.rf-unstyled-button-root {
  cursor: pointer;
  border: 0;
  padding: 0;
  appearance: none;
  font-size: inherit;
  background-color: transparent;
  text-align: left;
  color: inherit;
  text-decoration: none;
  box-sizing: border-box;

  -webkit-tap-highlight-color: transparent; 
}

.rf-unstyled-button-root:focus {
  outline: 1px solid transparent;
  outline-offset: 1px;
}
.rf-unstyled-button-root:focus-visible {
  border: 1px solid var(--clr-brand-default);
  /* TODO: Check can we do it using variables */
  box-shadow: 0px 0px 0px 2px #0057b740;
  transition-property: box-shadow;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}