.rf-tooltip__container {
  font-family: inherit;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;

  position: absolute;
  padding: 8px;
  background-color: var(--clr-text-primary);
  color: var(--clr-pure-white);
  border-radius: 4px;
  width: fit-content;
  z-index: 99;
}

.rf-tooltip__arrow {
  position: absolute;
  background: var(--clr-text-primary);
  width: 8px;
  height: 8px;
  transform: rotate(45deg);
}
