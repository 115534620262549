.form-signin {
  width: 100%;
  max-width: 420px;
  padding: 15px;
  margin: auto;
}

.form-signin-label-group > input,
.form-signin-label-group > label {
  height: 3.125rem;
  padding: .75rem;
}

.form-signin-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-signin-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-signin-label-group input::-ms-input-placeholder {
  color: transparent;
}

.form-signin-label-group input::-moz-placeholder {
  color: transparent;
}

.form-signin-label-group input::placeholder {
  color: transparent;
}

.form-signin-label-group input:not(:placeholder-shown) {
  padding-top: 1.25rem;
  padding-bottom: .25rem;
  color: var(--primary) !important;
}

.form-signin-label-group input:not(:placeholder-shown) ~ label {
  padding-top: .25rem;
  padding-bottom: .25rem;
  font-size: 10.6px;
  color: var(--primary) !important;
}

/* Fallback for Edge
-------------------------------------------------- */
@supports (-ms-ime-align: auto) {
  .form-signin-label-group > label {
    display: none;
  }
  .form-signin-label-group input::-ms-input-placeholder {
    color: var(--primary) !important;
  }
}

/* Fallback for IE
-------------------------------------------------- */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .form-signin-label-group > label {
    display: none;
  }
  .form-signin-label-group input:-ms-input-placeholder {
    color: var(--primary);
  }
}
