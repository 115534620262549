.rf-overlay__root {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 400;
}

.rf-overlay__blur {
  position: absolute;
  inset: 0px;
  z-index: 1000;
}
