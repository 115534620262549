.rf-radio-group__root {
  --clr-danger: var(--clr-info-error-default);
  --clr-warning: var(--clr-info-alert-dark);

  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: var(--clr-text-secondary);
}

.rf-radio-group__label {
  display: inline-block;
  font-size: 14px;
  word-break: break-word;
  cursor: default;

  margin-bottom: 6px;
}
.rf-radio-group__label-required {
  color: var(--clr-danger);
}

.rf-radio-group__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  min-height: 46px;
}

.rf-radio-group__error {
  font-size: 14px;
  text-decoration: none;
  word-break: break-word;
  margin-top: 6px;
  color: var(--clr-danger);
}
