.notification-box {
  padding: 20px 30px;
  border-left: 12px solid;
  border-radius: 8px;
  background-color: #ffffff;
  color: var(--primary);
}

.notification-box__message {
  font-size: 22px;
  font-weight: 600;
  margin: 10px 0;
}

.notification-box__description {
  font-size: 15px;
  font-weight: 400;
}

.notification-box__data-column-container {
  margin-top: 20px;
  margin-bottom: 20px;
}

.notification-box__data-column:not(:last-child) {
  border-right: 1px solid #dedede;
}

.notification-box__data-column--label {
  color: #a7a7a7;
  font-size: 14px;
  font-weight: 400;
}

.notification-box__data-column--value {
  color: inherit;
  font-size: 23px;
  font-weight: 600;
}

.notification-box__cta-btn {
  margin: 10px 0;
  font-size: 15px;
  font-weight: 500;
  color: #ffffff;
  background-color: var(--secondary);
  padding: 10px 20px;
}
.notification-box__cta-btn:hover {
  color: #ffffff;
  background-color: var(--secondary);
  opacity: 0.8;
}
