.rf-switch__wrapper {
  --switch-clr: var(--clr-pure-white);
  --switch-bg-clr: var(--clr-grey-default);
  --switch-height: 24px;
  --switch-track-width: 44px;
  --switch-font-size: 16px;
  --switch-font-weight: 400;
  --switch-thumb-width: 20px;
  --switch-track-padding: 2px;
  --switch-transition-duration: 150ms;

  --switch-label-font-size: 16px;
  --switch-label-font-weight: 400;
  --switch-label-line-height: 24px;
  --switch-description-font-size: 14px;
  --switch-description-font-weight: 400;
  --switch-description-line-height: 20px;

  display: inline-flex;
  align-items: center;
}

.rf-switch__wrapper.full-width {
  width: 100%;
  justify-content: space-between;
}

.rf-switch__wrapper-size-sm {
    --switch-height: 20px;
    --switch-track-width: 36px;
    --switch-thumb-width: 16px;
    --switch-label-font-size: 14px;
    --switch-description-font-size: 12px;
  }

.rf-switch__inner {
  position: relative;
}

.rf-switch__inner-position-left {
  order: 2;
}
.rf-switch__inner-position-right {
  order: 1;
}

.rf-switch__input {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.rf-switch__track {
  overflow: hidden;
  position: relative;
  border-radius: 12px;
  background-color: var(--switch-bg-clr);
  border: 1px solid var(--switch-bg-clr);
  height: var(--switch-height);
  min-width: var(--switch-track-width);
  margin: 0;
  appearance: none;
  display: flex;
  align-items: center;
  font-size: var(--switch-font-size);
  font-weight: var(--switch-font-weight);
  user-select: none;
  z-index: 0;
  line-height: 0;
  color: var(--switch-clr);
  transition: color var(--switch-transition-duration) ease;
  cursor: pointer;
}

.rf-switch__input:checked + .rf-switch__track {
  --switch-bg-clr: var(--clr-brand-default);
}

.rf-switch__input:disabled + .rf-switch__track {
  --switch-bg-clr: var(--clr-grey-light);
  cursor: not-allowed;
}

.rf-switch__thumb {
  position: absolute;
  z-index: 1;
  border-radius: 50%;
  display: flex;
  background-color: var(--clr-pure-white);
  height: var(--switch-thumb-width);
  width: var(--switch-thumb-width);
  border: 1px solid var(--clr-pure-white);
  left: var(--switch-track-padding);
  transition: left var(--switch-transition-duration) ease;
}

.rf-switch__thumb > * {
  margin: auto;
}

.rf-switch__input:checked + * > .rf-switch__thumb {
  left: calc(100% - var(--switch-thumb-width) - var(--switch-track-padding));
}

.rf-switch__track-label {
  height: 100%;
  display: grid;
  place-content: center;
  min-width: calc(var(--switch-track-width) - var(--switch-thumb-width));
  /* TODO: Change this based on size */
  padding-inline: 8px;
  margin: 0 0 0 calc(var(--switch-thumb-width) + var(--switch-track-padding));
  transition: margin var(--switch-transition-duration) ease;
}

.rf-switch__input:checked + * > .rf-switch__track-label {
  margin: 0 calc(var(--switch-thumb-width) + var(--switch-track-padding)) 0 0;
}

.rf-switch__label-wrapper {
  display: flex;
  flex-direction: column;
}
.rf-switch__label-wrapper-position-left {
  order: 1;
  padding-right: 8px;
  text-align: right;
}
.rf-switch__label-wrapper-position-right {
  order: 2;
  padding-left: 8px;
}

.rf-switch__label {
  font-family: inherit;
  font-size: var(--switch-label-font-size);
  font-weight: var(--switch-label-font-weight);
  line-height: var(--switch-label-line-height);
  color: var(--clr-text-primary);
  margin: 0;
}

.rf-switch__label-description {
  font-family: inherit;
  font-size: var(--switch-description-font-size);
  font-weight: var(--switch-description-font-weight);
  line-height: var(--switch-description-line-height);
  color: var(--clr-text-secondary);
}
