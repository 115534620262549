.dashboard {
  display: flex;
  width: 100%;
  height: 100vh;
}

.menu-wrapper {
  width: 250px;
}

.content {
  width: calc(100% - 250px);
}

.menu {
  padding: 20px 30px 30px 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.nav-logo {
  width: 110px;
}

.menu-item {
  font-size: 13px;
  color: var(--textgray);
  font-weight: 500;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 8px;
}

.menu-item svg {
  max-height: 22px;
}

.intro {
  background: var(--secondary);
  color: #ffffff;
  position: relative;
  min-height: 150px;
}

.intro-icon {
  position: absolute;
  right: 0px;
  width: 210px;
  bottom: 0px;
}

.menu-item svg {
  margin-right: 8px;
  width: 16px;
}
*:focus {
  outline: none;
}

.doc-upload-area {
  width: 30%;
  text-align: center;
}

.doc-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}
.doc-wrapper-with-history {
  height: unset;
}

.upload-area {
  background: var(--white);
  border: 2px dashed var(--primary);
  width: 100%;
  padding: 30px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.w-150 {
  width: 150px;
}

.page-title {
  color: var(--white);
}

.menu-item:hover {
  transition: 0.1s;
  color: #fff;
  background-color: var(--secondary);
}

.menu-item-selected {
  background-color: var(--secondary);
  color: #fff;
}

.toggler {
  display: grid;
  background: var(--base-blue) 0% 0% no-repeat padding-box;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  padding: 25px;
}

.toggler .screen-name {
  grid-column: 1/2;
  font-size: 20px;
  font-weight: bold;
}

.toggler .dropdown-options {
  grid-column: 3/4;
}

.toggler .dropdown-options .dropdown-box {
  z-index: 999;
  box-shadow: 0px 3px 35px #7e7e7e29;
  border-radius: 5px;
  background: white;
  font-size: 15px;
  text-align: center;
  min-height: 100px;
  position: fixed;
  width: 150px;
  margin: 20px;
  right: 0;
  padding: 20px 0;
}

.toggler .dropdown-options .dropdown-box ul {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
}

.toggler .dropdown-options .dropdown-box ul li {
  cursor: pointer;
}

.name-circle-toggler-sidenav {
  background: var(--secondary);
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: var(--white);
}
.name-circle-toggler {
  background: var(--white);
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: var(--primary);
}
.name-circle-toggler-profile {
  background: var(--base-blue);
  padding: 10px 18px;
  color: #ffffff;
  border-radius: 50%;
  font-weight: bold;
  font-size: 30px;
}

.route-area {
  height: calc(100vh - 90px);
  overflow-y: scroll;
  padding: 40px 30px;
}
.company-name-profile {
  font-size: 20px;
  font-weight: bold;
  vertical-align: top;
}
.ml-20 {
  margin-left: 20px;
}
.hr-partner-details {
  margin-top: 45px;
  margin-left: 55px;
}
.content {
  overflow-x: hidden;
  background-color: #f7f7f7;
}

.logout-btn {
  float: right;
  margin-right: 50px;
  margin-top: 5px;
}

#logo-sidenav {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.page-container {
  background: #ffffff;
  padding: 40px 80px;
  margin: 10px 30px;
  border-radius: 8px;
}

@media only screen and (max-width: 768px) {
  .menu-wrapper {
    width: 70px;
  }

  .content {
    width: calc(100% - 70px);
  }

  .menu {
    padding: 10px;
    justify-content: space-between;
  }

  .route-area {
    padding: 40px 10px;
  }
  .intro-icon {
    position: unset;
    width: 100px;
    margin-left: auto;
  }

  .doc-upload-area {
    width: 85%;
  }

  .page-container {
    padding: 30px 20px;
    margin: 10px 0px;
  }

  .fs-20 {
    font-size: 16px !important;
  }
}
