.summary-card {
    border-radius: 8px;
    background-color: #fff;
    text-align: center;
    padding-top: 10px;

    .card-logo-wrapper {
        width: 110px;
        height: 110px;
        display: inline-block;
        margin: 0px auto;
    }
    
    .logo-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .card-label {
        display: block;
        text-transform:capitalize;
    }

    .card-btn {
        width: 100%;
        border-radius: 0 0 8px 8px;
        padding: 15px 0;
        border: none;
        background-color: var(--secondary);
        color: #fff;
    }
}