.payroll-card {
  border-radius: 8px;
  padding: 30px;
  background-color: #fff;
}

.payroll-card.disabled {
  opacity: 0.7;
  pointer-events: none;
}

/* Monthly Summary Component --- Starts --- */

.payroll-monthly-summary {
  background-color: #fff;
  padding: 40px;
  border-radius: 8px;
}

.payroll-monthly-summary.disabled {
  opacity: 0.8;
}
.payroll-monthly-summary.disabled * {
  cursor: default;
  pointer-events: none;
  color: #A7A7A7;
}

.payroll-monthly-summary__action-link--icon {
  margin-right: 8px;
  max-height: 20px;
}

.payroll-monthly-summary__data-filed--row {
  margin-top: 30px;
  margin-bottom: 30px;
}

.payroll-monthly-summary__month-dropdown {
  background: transparent;
  border: none;
}

.payroll-monthly-summary__message-text {
  color: #a7a7a7;
  font-size: 14px;
  font-weight: 400;
}

/* Monthly Summary Component --- Ends --- */


/* Payroll Summary Card --- Starts --- */

.payroll-summary-card__month--text {
  font-weight: 500;
  font-size: 15px;
}

.payroll-summary-card__month--grey-outline-text {
  font-weight: 600;
  font-size: 18px;
  padding: 5px;
  background-color: #F5F5FC;
}

.payroll-summary-card__alert-box {
  background-color: #F5F5FC;
  font-size: 12px;
  font-weight: 600;
  padding: 10px;
  margin: 20px 0;
}

.payroll-summary-card__buttons * {
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.payroll-summary-card__buttons .btn-outline-primary {
  color: var(--secondary);
  border-color: var(--secondary);
}
.payroll-summary-card__buttons .btn-outline-primary:hover {
  color: #fff;
  background-color: var(--secondary);
}
.payroll-summary-card__buttons .btn-primary {
  background-color: var(--secondary);
}

.payroll-summary-card__status_text--success {
  font-size: 12px;
  font-weight: 400;
  color: #3BC88F;
}

.payroll-summary-card__text {
  font-size: 13px;
  font-weight: 500;
}
.payroll-summary-card__amount-text {
  font-size: 16px;
  font-weight: 600;
}

.payroll-summary-card__text--danger {
  color: #F38686;
}

/* Payroll Summary Card --- Ends --- */

.payroll-upload-summary__title {
  font-size: 18px;
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 20px;
}

.payroll-search-bar {
  display: flex;
  align-items: center;
}

.payroll-search-bar--input {
  max-width: 500px;
  margin-right: 20px;

  padding: 22px 20px;
  font-size: 15px;
}

.payroll-search-bar--button {
  padding: 10px 50px;
  font-size: 15px;
}
