.custom-toast {
  position: fixed;
  top: 20px;
  right: 0;
  width: 300px;
  color: white;
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
  height: 50px;
  text-align: center;
  z-index: 99;
}
.ct-open {
  width: 300px;
  -webkit-transition: width .3s ease;
}
.ct-close {
  width: 0;
  -webkit-transition: width .3s ease;
}

.rf-notifier {
  display: flex;
  padding: 25px;
  color: var(--primary);
}

.rf-notifier__icon--container {
  margin-right: 20px;
}

.rf-notifier__icon {
  width: 30px;
  height: 30px;
}

.rf-notifier__icon--wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  /* padding: 10px; */
  background: #f7f7f7;
  border-radius: 9999px;
}

.rf-notifier__title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 1rem;
}

.rf-notifier__message {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 1.5rem;
  white-space: pre-line;
}

.rf-notifier__btn {
  color: var(--secondary);
  font-weight: 500;
}