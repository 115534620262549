.modal {
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  z-index: 100;
  padding-top: 40px;
  & .modal-dialog {
    max-width: 650px;
    &.modal-x-lg {
      width: 90% !important;
    }
  }

  & .modal-body {
    max-height: 85vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px;
  }

  & .form-modal-container {
    display: flex;
    flex-wrap: wrap;

    & .form-group {
      flex: 1;
      flex-basis: 50%;
      padding: 0px 5px;

      &.full-width {
        flex-basis: 100%;
      }
    }
  }
}
.close-btn {
  border: 1px solid var(--primary);
  color: var(--primary);
  border-radius: 3px;
  cursor: pointer;
  background-color: #ffffff;
  padding: 5px 10px;
}
.submit-btn-modal {
  border: none;
  background: var(--primary) ;
  border-radius: 3px;
  cursor: pointer;
  color: #ffffff;
  padding: 5px 10px;
}
