.rf-table__container {
  background-color: var(--clr-pure-white);
  border-radius: 12px;
}

.rf-table__header {
  padding: 32px 32px 24px 18px;
}
.rf-table__footer {
  padding: 24px 32px 24px 18px;
}
.rf-table__wrapper {
  padding: 0;
  width: auto;
  overflow-x: auto;
}

.rf-table {
  border-collapse: collapse;
  border-spacing: 0;
  min-width: 100%;
}

.rf-table thead th {
  background-color: var(--clr-grey-light);
  color: var(--clr-text-secondary);
}
.rf-table tbody tr {
    background-color: var(--clr-pure-white);
}
.rf-table tbody td {
  background-color: inherit;
  color: var(--clr-text-primary);
}
.rf-table th,
.rf-table td {
  outline: 0;
  vertical-align: middle;
  text-align: left;
  padding: 8px;
}
.rf-table th {
  font-family: inherit;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}
.rf-table td {
  height: 52px;
  font-family: inherit;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

.rf-table tbody tr:not(:last-child) {
  border-bottom: 1px solid #dadbdc;
}
.rf-table__wrapper.has-footer .rf-table tbody tr:last-child {
  border-bottom: 1px solid #dadbdc;
}

.rf-table tbody tr:focus-within,
.rf-table tbody tr:hover {
  background-color: #EBF5FF;
}

.rf-table tbody tr.selected td {
  background-color: var(--clr-brand-light);
}

/* Global max-width check with Design */
/* .rf-table td,
.rf-table th {
  max-width: 200px;
} */

.rf-table__table-header-cell,
.rf-table__table-data-cell {
  display: flex;
  min-width: 0;
  align-items: center;
}

.rf-table__table-header-cell__label,
.rf-table__table-data-cell__label {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 5px;
}

.rf-table__table-header-cell__actions,
.rf-table__table-data-cell__actions {
  margin-left: 8px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

.rf-table__table-data-cell__actions {
  margin-left: 6px;
  display: flex;
  visibility: hidden;
  align-items: center;
}

.rf-table tbody tr:focus-within .rf-table__table-data-cell__actions.visible-hover,
.rf-table tbody tr:hover .rf-table__table-data-cell__actions.visible-hover,
.rf-table__table-data-cell__actions.visible-always {
  visibility: visible;
}

.rf-table__table-cell__checkbox {
  padding-left: 16px !important;
  position: sticky;
  left: 0;
  z-index: 99;
  width: 40px;
}
