.rf-button-root {
  --clr-white: var(--clr-pure-white);
  --clr-disabled-bg: var(--clr-grey-dark);
  --clr-disabled-text: var(--clr-text-secondary);

  --clr-primary-default: var(--clr-brand-default);
  --clr-primary-light: var(--clr-brand-light);
  --clr-primary-dark: var(--clr-brand-dark);

  --clr-danger-default: var(--clr-info-error-default);
  --clr-danger-light: var(--clr-info-error-light);
  --clr-danger-dark: var(--clr-info-error-dark);

  --clr-success-default: var(--clr-info-success-default);
  --clr-success-light: var(--clr-info-success-light);
  --clr-success-dark: var(--clr-info-success-dark);

  border-radius: 8px;
  border: 1px solid transparent;
  font-weight: 600;
  position: relative;
  line-height: 24px;
  font-size: 16px;
  user-select: none;
}

.rf-button-root:active {
  transform: translateY(1px);
}
.rf-button-root:active:disabled {
  transform: none;
}
.rf-button-root:disabled {
  border-color: transparent;
  color: var(--clr-disabled-text) !important;
  cursor: not-allowed;
}
.rf-button-root.loading {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.9;
}

/* Primary */

.rf-button-root.variant-primary.color-brand {
  color: var(--clr-white);
  background-color: var(--clr-brand-default);
  border-color: var(--clr-brand-default);
}
.rf-button-root.variant-primary.color-danger {
  color: var(--clr-white);
  background-color: var(--clr-danger-default);
  border-color: var(--clr-danger-default);
}
.rf-button-root.variant-primary.color-success {
  color: var(--clr-white);
  background-color: var(--clr-success-default);
  border-color: var(--clr-success-default);
}

.rf-button-root:hover.variant-primary.color-brand {
  background-color: var(--clr-brand-dark);
  border-color: var(--clr-brand-dark);
}
.rf-button-root:hover.variant-primary.color-danger {
  background-color: var(--clr-danger-dark);
  border-color: var(--clr-danger-dark);
}
.rf-button-root:hover.variant-primary.color-success {
  background-color: var(--clr-success-dark);
  border-color: var(--clr-success-dark);
}

.rf-button-root:disabled.variant-primary {
  background-color: var(--clr-grey-dark) !important;
  border-color: var(--clr-grey-dark) !important;
}

/* Secondary */

.rf-button-root.variant-secondary {
  background-color: var(--clr-white);
}

.rf-button-root.variant-secondary.color-brand {
  color: var(--clr-brand-default);
  border-color: var(--clr-brand-default);
}
.rf-button-root.variant-secondary.color-danger {
  color: var(--clr-danger-default);
  border-color: var(--clr-danger-default);
}
.rf-button-root.variant-secondary.color-success {
  color: var(--clr-success-default);
  border-color: var(--clr-success-default);
}

.rf-button-root:hover.variant-secondary.color-brand {
  color: var(--clr-brand-dark);
  border-color: var(--clr-brand-dark);
}
.rf-button-root:hover.variant-secondary.color-danger {
  color: var(--clr-danger-dark);
  border-color: var(--clr-danger-dark);
}
.rf-button-root:hover.variant-secondary.color-success {
  color: var(--clr-success-dark);
  border-color: var(--clr-success-dark);
}

.rf-button-root:disabled.variant-secondary {
  border-color: var(--clr-grey-dark) !important;
}

/* Text */

.rf-button-root.variant-text {
  background-color: var(--clr-white);
}

.rf-button-root.variant-text.color-brand {
  color: var(--clr-brand-default);
}
.rf-button-root.variant-text.color-danger {
  color: var(--clr-danger-default);
}
.rf-button-root.variant-text.color-success {
  color: var(--clr-success-default);
}

.rf-button-root:hover.variant-text.color-brand {
  color: var(--clr-brand-dark);
}
.rf-button-root:hover.variant-text.color-danger {
  color: var(--clr-danger-dark);
}
.rf-button-root:hover.variant-text.color-success {
  color: var(--clr-success-dark);
}

/* Size */
.rf-button-root.size-sm {
  height: 32px;
  min-width: 130px;
  font-weight: 500;
  line-height: 20px;
  font-size: 14px;
}
.rf-button-root.size-md {
  height: 48px;
  font-weight: 500;
  line-height: 20px;
  font-size: 14px;
  min-width: 154px;
}
.rf-button-root.size-lg {
  height: 56px;
  min-width: 271px;
}

/* Spacing */
.rf-button-root.spacing-regular {
  padding: 0 16px;
}
.rf-button-root.spacing-compact {
  padding: 0 12px;
}
.rf-button-root.spacing-full-width {
  width: 100%;
}

.rf-button__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: visible;
}

.rf-button__label {
  white-space: nowrap;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.rf-button__left-section {
  margin-right: 12px;
  height: 24px;
  width: 24px;
  transition: 0.4s ease-in-out;
}
.rf-button__right-section {
  margin-left: 12px;
  height: 24px;
  width: 24px;
  transition: 0.4s ease-in-out;
}

/* TODO: Handle for small buttons */
.rf-button-root:hover .rf-button__left-section {
  transform: translateX(-8px);
}
.rf-button-root:hover .rf-button__right-section {
  transform: translateX(8px);
}
