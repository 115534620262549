.rf-label {
    --label-success: #43B96F;
    --label-success-bg: #ECFFF1;
    --label-pending: #0057B7;
    --label-pending-bg: #F1FAFF;
    --label-reject: #DA7C2A;
    --label-reject-bg: #FFF5F2;
    --label-info: #495057;
    --label-info-bg: #f5f5fc;

    width: 100%;
    text-align: center;
    font-family: inherit;
    font-weight: 400;
    font-size: 12px;
    padding: 10px;
}

.rf-label--success {
    color: var(--label-success);
    background-color: var(--label-success-bg);
}
.rf-label--pending {
    color: var(--label-pending);
    background-color: var(--label-pending-bg);
}
.rf-label--reject {
    color: var(--label-reject);
    background-color: var(--label-reject-bg);
}
.rf-label--info {
    color: var(--label-info);
    background-color: var(--label-info-bg);
}