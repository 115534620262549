.sortable-icon {
  display: inline-block;
  height: 11px;
  position: relative;
  width: 10px;
}

.sortable-icon:before,
.sortable-icon:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  content: "";
  left: 0;
  position: absolute;
}

.sortable-icon:before {
  border-bottom: 4px solid var(--clr-grey-dark);
}

.sortable-icon:after {
  border-top: 4px solid var(--clr-grey-dark);
  bottom: 0;
}

.sorted-by-ascending.sortable-icon::before {
  border-bottom: 4px solid var(--clr-text-primary);
}
.sorted-by-descending.sortable-icon::after {
  border-top: 4px solid var(--clr-text-primary);
}
