.info-bar {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px 30px;

  display: flex;
  align-items: center;
  justify-content: space-around;
}

.info-bar__data-container {
  display: flex;
  align-items: center;
}

.info-bar__data-container--label {
  margin: 0;
  margin-right: 15px;
  color: #a7a7a7;
  font-size: 12px;
  font-weight: 400;
}

.info-bar__data-container--value {
  margin: 0;
  color: #15171A;
  font-size: 18px;
  font-weight: 600;
}
