.otp-box {
  width: 30px;
  border: none;
  margin: 13px 6px;
  padding: 0px 15px;
  font-size: 34px;
  font-weight: 900;
  color: var(--primary);
  border-bottom: 2px solid var(--lightgray);
}
.otp-text {
  border: none;
  width: 30px;
  margin: 13px 6px;
  background: transparent;
  text-align: center;
  padding: 0px;
  font-size: 34px;
  font-weight: 900;
  border-bottom: 2px solid var(--primary);
  color: var(--primary);
}

.otp-box:focus,
.otp-text:focus {
  outline: none !important;
  box-shadow: none !important;
  border-bottom: 2px solid var(--primary);
}
.m-60 {
  margin: 0px 60px;
}
