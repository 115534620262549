.amount-deducted-tab {
  color: var(--green);
  background-color: var(--lightGreen);
}

.amount-repaid-tab {
  background-color: var(--clr-info-success-light) !important;
  color: var(--clr-info-success-dark) !important;
}

.amount-transfered-tab {
  background-color: var(--clr-info-highlight-light) !important;
  color: var(--clr-info-highlight-dark) !important;
}
