.rf-tag__wrapper {
  --clr-bg: var(--clr-brand-default);
  --clr-content: var(--clr-pure-white);

  -webkit-tap-highlight-color: transparent;
  font-family: inherit;
  font-size: 12px;
  height: 20px;
  line-height: 16px;
  text-decoration: none;
  padding: 0px 8px;
  box-sizing: border-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: auto;
  border-radius: 4px;
  font-weight: 500;
  cursor: inherit;
  text-overflow: ellipsis;
  overflow: hidden;
  background-color: var(--clr-bg);
  color: var(--clr-content);
}

.rf-tag__color-alert {
  --clr-bg: var(--clr-info-alert-light);
  --clr-content: var(--clr-info-alert-dark);
}
.rf-tag__color-success {
  --clr-bg: var(--clr-info-success-light);
  --clr-content: var(--clr-info-success-dark);
}
.rf-tag__color-grey {
  --clr-bg: var(--clr-grey-light);
  --clr-content: var(--clr-text-secondary);
}
.rf-tag__color-white {
  --clr-bg: var(--clr-pure-white);
  --clr-content: var(--clr-text-primary);
}
.rf-tag__color-highlight {
  --clr-bg: var(--clr-info-highlight-light);
  --clr-content: var(--clr-info-highlight-dark);
}
.rf-tag__color-error {
  --clr-bg: var(--clr-info-error-light);
  --clr-content: var(--clr-info-error-dark);
}

.rf-tag__dot-left::before,
.rf-tag__dot-right::after {
  content: "";
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 2px;
  background-color: var(--clr-content);
}

.rf-tag__dot-left::before {
  margin-right: 8px;
}
.rf-tag__dot-right::after {
  margin-left: 8px;
}

.rf-tag__full-width {
  width: 100%;
}

.rf-tag__rounded {
  border-radius: 16px;
}

.rf-tag__inner {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rf-tag__left {
  margin-right: 8px;
}
.rf-tag__right {
  margin-left: 8px;
}
