.data-field {
  color: var(--primary);

  display: flex;
  /* align-items: center; */
}

.data-field--label {
  color: #a7a7a7;
  font-size: 14px;
  font-weight: 400;
}

.data-field--value {
  color: inherit;
  font-size: 22px;
  font-weight: 600;
}
.data-field--value.clickable {
  cursor: pointer;
  color: var(--secondary);
}

.data-field--icon {
  max-height: 50px;
  margin-right: 20px;
}
