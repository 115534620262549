.rf-input__wrapper {
  --clr-danger: var(--clr-info-error-default);
  --clr-warning: var(--clr-info-alert-dark);

  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: var(--clr-text-secondary);
}

.rf-input__label {
  display: inline-block;
  font-size: 14px;
  word-break: break-word;
  cursor: default;

  margin-bottom: 6px;
}
.rf-input__label-required {
  color: var(--clr-danger);
}

.rf-input__container {
  position: relative;
}

.rf-input__container__left-section {
  pointer-events: none;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
}
.rf-input__container__right-section {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
}

.rf-input__container__input.left-section {
  padding-left: 36px;
}
.rf-input__container__input.right-section {
  padding-right: 32px;
}

.rf-input__container__input {
  line-height: 24px;
  appearance: none;
  resize: none;
  font-size: inherit;
  width: 100%;
  color: var(--clr-text-primary);
  display: block;
  text-align: left;
  padding: 10px 14px;
  border-radius: 8px;
  border: 1px solid var(--clr-grey-default);
  background-color: var(--clr-pure-white);
  transition: border-color 100ms ease 0s;
}

.rf-input__container__input[type="number"]::-webkit-inner-spin-button,
.rf-input__container__input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* INFO: Hack to remove browsers default autofill color */
.rf-input__container__input:-webkit-autofill,
.rf-input__container__input:-webkit-autofill:hover,
.rf-input__container__input:-webkit-autofill:focus,
.rf-input__container__input:-webkit-autofill:active,
.rf-input__container__input:-internal-autofill-selected {
  transition: background-color 2000s 0s, color 2000s 0s;
}

.rf-input__container__input::placeholder {
  user-select: none;
  color: var(--clr-grey-default);
}

.rf-input__container__input:hover {
  border-color: var(--clr-grey-dark);
}

.rf-input__container__input:disabled {
  cursor: not-allowed;
  color: var(--clr-grey-default);
  background-color: var(--clr-grey-light);
}
.rf-input__container__input:hover:disabled {
  border-color: var(--clr-grey-default);
}

.rf-input__container__input:focus {
  outline: 1px solid transparent;
  outline-offset: 1px;
}
.rf-input__container__input:focus-visible {
  border: 1px solid var(--clr-brand-default);
  /* TODO: Check can we do it using variables */
  box-shadow: 0px 0px 0px 2px #0057b740;
  transition-property: box-shadow;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.rf-input__error,
.rf-input__warning,
.rf-input__hint {
  font-size: 14px;
  text-decoration: none;
  word-break: break-word;
  margin-top: 6px;
}

.rf-input__error,
.error .rf-input__label,
.error .rf-input__container__right-section {
  color: var(--clr-danger);
}

.error .rf-input__container__input {
  border-color: var(--clr-danger);
}

.rf-input__warning,
.warning .rf-input__container__right-section {
  color: var(--clr-warning);
}

.warning .rf-input__container__input {
  border-color: var(--clr-warning);
}
