.popover__wrapper {
  position: relative;
}

.popover-dropdown {
  position: absolute;
  z-index: 99;
  right: 0;
  left: auto;
  top: 100%;
  cursor: default;
  display: inline-block;
  vertical-align: top;
  padding: 8px;
  margin: 8px 0 0;
  width: auto;
  min-width: 140px;
  max-width: 720px;
  max-height: 330px;
  overflow-x: hidden;
  overflow-y: auto;
  background: var(--clr-pure-white);
  border-radius: 16px;
  box-shadow: 0px 5px 20px rgba(21, 23, 26, 0.12);
}

.popover-dropdown.popover-dropdown-loc-bottom-right {
  right: auto;
  left: 0;
}
.popover-dropdown.popover-dropdown-loc-bottom-center {
  right: auto;
  left: 50%;
  transform: translateX(-50%);
}
