a:hover {
  text-decoration: none;
  color: inherit;
}

.auth-page-wrapper {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--gray-background-color);
}

.auth-card {
  width: 70%;
  height: calc(100vh - 150px);
  background-color: var(--white);
  border-radius: 5px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.illustartion-box {
  width: 50%;
  height: 100%;
}

.illustartion-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.auth-main-content {
  width: 50%;
  height: 100%;
  padding: 10px 40px 10px 40px;
  display: flex;
  flex-direction: column;
}

.auth-logo {
  width: 130px;
}

.auth-form-wrapper {
  width: 70%;
  margin: 0px auto;
  flex: 14;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.auth-small-card {
  background-color: var(--white);
  border-radius: 8px;
  padding: 40px 75px;
  width: 32%;
}

.eye {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translate(0%, -50%);
}

.bottom-form-detail {
  flex: 1;
}
@media only screen and (max-width: 1024px) {
  .illustartion-box {
    display: none;
  }

  .auth-main-content {
    width: 100%;
  }

  .auth-small-card {
    width: 60%;
  }
}

@media only screen and (max-width: 768px) {
  .auth-form-wrapper {
    width: 90%;
  }

  .auth-small-card {
    border-radius: 8px;
    padding: 20px;
    width: 90%;
  }
}

@media only screen and (max-width: 425px) {
  .auth-card {
    width: 95%;
  }

  .auth-main-content {
    padding: 15px;
  }
}
