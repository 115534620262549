.rf-icon-button-root {
    /* TODO: Implement size & variant (maybe) */

    --clr-white: var(--clr-pure-white);
    --clr-disabled-bg: var(--clr-grey-dark);

    --clr-primary-default: var(--clr-brand-default);
    --clr-primary-light: var(--clr-brand-light);
    --clr-primary-dark: var(--clr-brand-dark);

    --clr-danger-default: var(--clr-info-error-default);
    --clr-danger-light: var(--clr-info-error-light);
    --clr-danger-dark: var(--clr-info-error-dark);

    --clr-success-default: var(--clr-info-success-default);
    --clr-success-light: var(--clr-info-success-light);
    --clr-success-dark: var(--clr-info-success-dark);

    cursor: pointer;
    appearance: none;
    font-size: 16px;
    position: relative;
    border: 1px solid transparent;
    border-radius: 8px;
    line-height: 1;
    background-color: transparent;
}

.rf-icon-button-root.variant-light {
    background-color: var(--clr-grey-light);
}

.rf-icon-button-root:active {
    transform: translateY(1px);
  }

.rf-icon-button-root.loading {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.9;
}
.rf-icon-button-root:disabled {
    cursor: not-allowed;
    color: var(--clr-disabled-bg) !important;
}

.rf-icon-button-root.color-brand {
    color: var(--clr-primary-default);
}
.rf-icon-button-root:hover.color-brand {
    color: var(--clr-primary-dark);
}
.rf-icon-button-root:hover.color-brand.variant-light {
    background-color: var(--clr-primary-light);
}
.rf-icon-button-root.color-success {
    color: var(--clr-success-default);
}
.rf-icon-button-root:hover.color-success {
    color: var(--clr-success-dark);
}
.rf-icon-button-root:hover.color-success.variant-light {
    background-color: var(--clr-success-light);
}
.rf-icon-button-root.color-danger {
    color: var(--clr-danger-default);
}
.rf-icon-button-root:hover.color-danger {
    color: var(--clr-danger-dark);
}
.rf-icon-button-root:hover.color-danger.variant-light {
    background-color: var(--clr-danger-light);
}
.rf-icon-button-root.size-md,
.rf-icon-button-root.size-sm {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}
.rf-icon-button__inner {
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 0;
}
.rf-icon-button__inner.spacing-compact {
    padding: 4px;
}
.rf-icon-button__inner.spacing-no-padding {
    padding: 0;
}
