.rf-radio__wrapper {
  --clr-white: var(--clr-pure-white);
  --clr-text: var(--clr-text-primary);
  --clr-brand: var(--clr-brand-default);
  --clr-disabled: var(--clr-grey-default);
  --clr-disabled-text: var(--clr-text-tertiary);

  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.rf-radio__element {
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--clr-white);
  margin: 0;
  font: inherit;
  border: 1.5px solid var(--clr-brand);
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-property: background-color, border-color;
  transition-timing-function: ease;
  transition-duration: 100ms;
  cursor: default;
}

.rf-radio__element:disabled {
  border: 1.5px solid var(--clr-disabled);
  cursor: not-allowed;
}

.rf-radio__element::before {
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 12px 12px var(--clr-brand);
}

.rf-radio__element:disabled:before {
  box-shadow: inset 12px 12px var(--clr-disabled);
}

.rf-radio__element:checked:before {
  transform: scale(1);
}

.rf-radio__element:focus {
  border: 1px solid var(--clr-brand);
  /* TODO: Check can we do it using variables */
  box-shadow: 0px 0px 0px 2px #0057b740;
  transition-property: box-shadow;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.rf-radio__label {
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: var(--clr-text);
  padding-left: 10px;
  cursor: default;
  margin: 0;
}

.rf-radio__label.disabled {
  color: var(--clr-disabled-text);
  pointer-events: none;
}
