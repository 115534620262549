.rf-drawer__root {
  position: fixed;
  z-index: 200;
  inset: 0px;
}

.rf-drawer__container {
  max-width: 100%;
  max-height: 100vh;
  position: fixed;
  outline: 0;
  z-index: 1;
  background-color: var(--clr-pure-white);
}

.rf-drawer__body {
  width: 100%;
  height: 100%;
  overflow: auto;
}

/* TODO: Work on transitions */
.rf-drawer__overlay {
    transition-property: opacity;
    transition-duration: 125ms;
    transition-timing-function: ease;
    opacity: 1;
}

.rf-drawer__close {
    position: absolute;
}