.rf-content__root {
    height: 100%;
    width: 100%;
}
.rf-content__root.fixed {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

.rf-content__header {
    padding: 24px 40px;
    /* TODO: Make it using variables */
    box-shadow: 0px 2px 2px rgba(21, 23, 26, 0.05);
    overflow-x: hidden;
}
.rf-content__header.fixed {
    flex-shrink: 0;
}

.rf-content__main {
    padding: 40px;
}
.rf-content__main.fixed {
    flex-grow: 1;
    overflow: auto;
    position: relative;
    /* min-height: 200px; */
}

.rf-content__footer {
    padding: 24px 40px;
    /* TODO: Make it using variables */
    box-shadow: 0px -2px 2px rgba(21, 23, 26, 0.05);
    overflow-x: hidden;
}
.rf-content__footer.fixed {
    flex-shrink: 0;
}