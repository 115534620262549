.tab-bar {
    list-style: none;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    user-select: none;

    color: #A7A7A7;
    font-size: 14px;
    font-weight: 400;
}

.tab-bar__tab {
    padding: 12px 45px;
    background-color: #E8E8E8;;
    box-shadow: 3px 3px 10px #00000029;
    border: 1px solid #E8E8E8;
    border-radius: 8px 8px 0px 0px;
    cursor: pointer;
}
.tab-bar__tab.active {
    color: #fff;
    background-color: var(--secondary);
    box-shadow: 3px 3px 10px #00000029;
    border: 1px solid var(--secondary);
    font-weight: 500;
    pointer-events: none;
}
.tab-bar__tab.disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.8;
}