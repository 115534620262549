@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600&display=swap);
:root {
  /* Legacy variables */
  --primary: #15171A;
  --secondary: #0057B7;
  --lightbg: #f5f5f5;
  --textgray: #495057;
  --max-width: 100vw;
  --lightgray: #707070;
  --gray-background-color: #f5f5fc;
  --error-background: #ffcccb;
  --green: #43b96f;
  --lightGreen: #ecfff1;
  --red: red;

  /* Branding Colours */
  /* INFO: These variables will be used throughout */
  --clr-brand-default: #0057B7;
  --clr-brand-light: #E5EEF8;
  --clr-brand-dark: #003D80;

  --clr-text-primary: #15171A;
  --clr-text-secondary: #696D70;
  --clr-text-tertiary: #999EA3;

  --clr-grey-default: #C9CDD1;
  --clr-grey-light: #F5F7FA;
  --clr-grey-dark: #B8BBBF;

  --clr-pure-white: #FFFFFF;

  --clr-info-error-default: #D13B3B;
  --clr-info-error-light: #F8E2E2;
  --clr-info-error-dark: #781313;

  --clr-info-success-default: #2C9E82;
  --clr-info-success-light: #DCF5EF;
  --clr-info-success-dark: #0C7057;

  --clr-info-alert-default: #EC9527;
  --clr-info-alert-light: #FCF2E3;
  --clr-info-alert-dark: #8A5006;

  --clr-info-highlight-default: #F5C245;
  --clr-info-highlight-light: #FCEECA;
  --clr-info-highlight-dark: #AC7D08;
}

* {
  font-family: 'Work Sans', sans-serif;
}

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

.primarycolor {
  color: #0057B7;
  color: var(--secondary);
}

.primary-color-bg {
  background-color: #0057B7;
  background-color: var(--secondary);
}
.bg-lightgray {
  background: #e7e7e7;
}
.body-bg {
  background-color: #f5f5fc;
}

.body-bg1 {
  background-color: #f5f5f5;
  background-color: var(--lightbg);
}

.secondary-bg {
  background-color: #0057B7 !important;
  background-color: var(--secondary) !important;
}

.calc-table td,
.calc-table th {
  font-size: 0.85rem;
  border: none !important;
  padding: 0.7rem;
}

.calc-result th,
.calc-result td {
  font-size: 1rem;
}
::-ms-fill-lower {
  background: dodgerblue;
}
.font-weight-bold {
  font-weight: bold;
}
.font-size-small {
  font-size: small;
}
.color-white {
  color: #ffffff;
}
.border-dash {
  border-style: dashed;
}
.display-none {
  display: none;
}
.white-bg {
  background-color: #ffffff !important;
}
.tnc-font {
  font-size: 9px;
}
.cursor-pointer {
  cursor: pointer;
}

.attendance-cycle-data {
  border-top: 1px solid gainsboro;
}

.loader-container {
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  background-color: var(--lightbg);
}

.loader-container div {
  position: fixed;
  top: 43vh;
  left: 45vw;
  color: #15171A;
  color: var(--primary);
  font-size: 7px;
}

.primary-color {
  color: #15171A !important;
  color: var(--primary) !important;
}
.secondary-color {
  color: #0057B7 !important;
  color: var(--secondary) !important;
}

.error-msg {
  font-size: small;
  color: red;
}
.error-border {
  border: 1px solid red;
}
/* CSS for table */
.table-wrapper {
  position: relative;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  min-height: 0;
  background: white;
  border-radius: 8px;
  padding: 20px 50px;
}
.table-title {
  color: #15171A;
  color: var(--primary);
}
.cGkIHG {
  display: none !important;
}
.ejpJLV {
  display: none !important;
}
.hXsnMM {
  display: none !important;
}
.single-line-focus:focus {
  outline: none;
  box-shadow: none;
}

.icon-h-56 {
  height: 56px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border: 4px solid transparent;
  border-radius: 9px;
  background-clip: content-box;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  border: 4px solid transparent;
  border-radius: 9px;
  background-clip: content-box;
}

.inner-circle-todo {
  height: 20px;
  width: 20px;
  background: #0057B7;
  background: var(--secondary);
  margin: 4px;
  border-radius: 50%;
}
.outer-circle-todo {
  height: 30px;
  width: 30px;
  background: white;
  display: inline-block;
  border-radius: 50%;
  margin-right: 10px;
  border: 1px solid #0057B7;
  border: 1px solid var(--secondary);
}
.todo-text {
  display: inline-block;
  vertical-align: top;
  margin-top: 4px;
}
.CircularProgressbar-path {
  stroke: red;
}
.CircularProgressbar-trail {
  stroke: gray;
}
.CircularProgressbar-text {
  fill: yellow;
}
.CircularProgressbar-background {
  fill: green;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-28 {
  font-size: 28px !important;
}

.white-color {
  color: var(--white);
}

.light-gray {
  color: #707070;
  color: var(--lightgray);
}

.green-color {
  color: #43b96f;
  color: var(--green);
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 600 !important;
}

.w-unset {
  width: unset !important;
}

.h-unset {
  height: unset !important;
}

.break-word {
  overflow-wrap: break-word !important;
}

.signed-up-btn {
  color: #43b96f;
  color: var(--green);
  background-color: #ecfff1;
  background-color: var(--lightGreen);
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 10px;
  min-width: 100px;
  text-align: center;
}

.not-signed-up-btn {
  color: #495057;
  color: var(--textgray);
  background-color: #f5f5fc;
  background-color: var(--gray-background-color);
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 10px;
  min-width: 100px;
  text-align: center;
}

.success-btn {
  color: #43b96f;
  color: var(--green);
  background-color: #ecfff1;
  background-color: var(--lightGreen);
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 10px;
  min-width: 100px;
  text-align: center;
}

.error-btn {
  color: red;
  color: var(--red);
  background-color: #ffcccb;
  background-color: var(--error-background);
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 10px;
  min-width: 100px;
  text-align: center;
}

button:disabled {
  cursor: not-allowed;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.subheading {
  font-size: 20px;
  font-weight: 600;
  color: #15171A;
  color: var(--primary);
  margin: 0px;
}
.primary-button {
  background-color: #0057B7;
  background-color: var(--secondary);
  color: white;
  height: 48px;
  border-radius: 6px;
  display: block;
  width: 100%;
  transition: 0.5s;
}

.primary-button:hover {
  color: white;
  opacity: 0.8;
}

.modalWrapper {
  background-color: var(--white);
  padding: 50px 20px;
  position: relative;
}

.abs-top-right-btn {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 100;
}

.outline-btn {
  display: inline-block;
  border: 1px solid #15171A;
  border: 1px solid var(--primary);
  padding: 10px 25px;
}

.b-color-red {
  border-color: red !important;
}
.b-color-secondary {
  border-color: #0057B7 !important;
  border-color: var(--secondary) !important;
}

.color-red {
  color: red !important;
}

.circle-avatar {
  width: 80px;
  height: 80px;
  border: 2px solid var(--white);
  box-shadow: 0px 0px 10px whitesmoke;
  display: inline-block;
  border-radius: 50%;
}

.circle-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.stats-icon-wrapper {
  background: whitesmoke;
  padding: 14px 18px;
  border-radius: 10px;
}

.month-wrapper {
  padding: 5px 20px;
  border-radius: 20px;
  background-color: whitesmoke;
}

.stats-wrapper {
  padding: 30px 25px;
  border-radius: 10px;
}

.bg-transparent {
  background-color: transparent !important;
}

.secondary-button {
  border: 1px solid #0057B7;
  border: 1px solid var(--secondary);
  color: #0057B7;
  color: var(--secondary);
  height: 48px;
  border-radius: 5px;
  display: block;
  min-width: 130px;
  transition: 0.5s;
  font-size: 12px;
  font-weight: 600;
}

.secondary-button:hover {
  opacity: 0.8;
  color: #0057B7;
  color: var(--secondary);
}

.fw-500 {
  font-weight: 500 !important;
}

.text-primary, .btn-link {
  color: #0057B7 !important;
  color: var(--secondary) !important;
}

.input-login {
  background-color: #e4e5e7 !important;
  border: #e4e5e7 !important;
  font-size: small;
  font-weight: 600;
  border-radius: 6px;
}
.input-login:-webkit-autofill,
.input-login:-webkit-autofill:hover,
.input-login:-webkit-autofill:focus,
.input-login:-webkit-autofill:active {
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #15171A;
  -webkit-text-fill-color: var(--primary);
}

.form-signin-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0; /* Override default `<label>` margin */
  line-height: 1.5;
  color: #495057;
  color: var(--textgray);
  pointer-events: none;
  cursor: text; /* Match the input under the label */
  border: 1px solid transparent;
  transition: all 0.1s ease-in-out;
}

.form-signin-label-group {
  position: relative;
  margin-bottom: 8px;
}

.dRHSWe {
  border-bottom-color: whitesmoke !important;
  padding: 15px 0px !important;
}

.cTEQON {
  background-color: whitesmoke !important;
  border-bottom: none !important;
}

.sc-AxhCb {
  color: #15171A !important;
  color: var(--primary) !important;
}

.doBktq {
  padding: 25px 0px !important;
  border-bottom-color: whitesmoke !important;
}

.sc-fzoyAV {
  width: 100% !important;
}

.redirect-arrow {
  position: absolute;
  right: 10px;
  top: 10px
}

@media only screen and (max-width: 425px) {
  .table-wrapper {
    padding: 20px 15px;
  }
}

.dashboard {
  display: flex;
  width: 100%;
  height: 100vh;
}

.menu-wrapper {
  width: 250px;
}

.content {
  width: calc(100% - 250px);
}

.menu {
  padding: 20px 30px 30px 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.nav-logo {
  width: 110px;
}

.menu-item {
  font-size: 13px;
  color: var(--textgray);
  font-weight: 500;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 8px;
}

.menu-item svg {
  max-height: 22px;
}

.intro {
  background: var(--secondary);
  color: #ffffff;
  position: relative;
  min-height: 150px;
}

.intro-icon {
  position: absolute;
  right: 0px;
  width: 210px;
  bottom: 0px;
}

.menu-item svg {
  margin-right: 8px;
  width: 16px;
}
*:focus {
  outline: none;
}

.doc-upload-area {
  width: 30%;
  text-align: center;
}

.doc-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}
.doc-wrapper-with-history {
  height: unset;
}

.upload-area {
  background: var(--white);
  border: 2px dashed var(--primary);
  width: 100%;
  padding: 30px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.w-150 {
  width: 150px;
}

.page-title {
  color: var(--white);
}

.menu-item:hover {
  transition: 0.1s;
  color: #fff;
  background-color: var(--secondary);
}

.menu-item-selected {
  background-color: var(--secondary);
  color: #fff;
}

.toggler {
  display: grid;
  background: var(--base-blue) 0% 0% no-repeat padding-box;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  padding: 25px;
}

.toggler .screen-name {
  grid-column: 1/2;
  font-size: 20px;
  font-weight: bold;
}

.toggler .dropdown-options {
  grid-column: 3/4;
}

.toggler .dropdown-options .dropdown-box {
  z-index: 999;
  box-shadow: 0px 3px 35px #7e7e7e29;
  border-radius: 5px;
  background: white;
  font-size: 15px;
  text-align: center;
  min-height: 100px;
  position: fixed;
  width: 150px;
  margin: 20px;
  right: 0;
  padding: 20px 0;
}

.toggler .dropdown-options .dropdown-box ul {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
}

.toggler .dropdown-options .dropdown-box ul li {
  cursor: pointer;
}

.name-circle-toggler-sidenav {
  background: var(--secondary);
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: var(--white);
}
.name-circle-toggler {
  background: var(--white);
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: var(--primary);
}
.name-circle-toggler-profile {
  background: var(--base-blue);
  padding: 10px 18px;
  color: #ffffff;
  border-radius: 50%;
  font-weight: bold;
  font-size: 30px;
}

.route-area {
  height: calc(100vh - 90px);
  overflow-y: scroll;
  padding: 40px 30px;
}
.company-name-profile {
  font-size: 20px;
  font-weight: bold;
  vertical-align: top;
}
.ml-20 {
  margin-left: 20px;
}
.hr-partner-details {
  margin-top: 45px;
  margin-left: 55px;
}
.content {
  overflow-x: hidden;
  background-color: #f7f7f7;
}

.logout-btn {
  float: right;
  margin-right: 50px;
  margin-top: 5px;
}

#logo-sidenav {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.page-container {
  background: #ffffff;
  padding: 40px 80px;
  margin: 10px 30px;
  border-radius: 8px;
}

@media only screen and (max-width: 768px) {
  .menu-wrapper {
    width: 70px;
  }

  .content {
    width: calc(100% - 70px);
  }

  .menu {
    padding: 10px;
    justify-content: space-between;
  }

  .route-area {
    padding: 40px 10px;
  }
  .intro-icon {
    position: unset;
    width: 100px;
    margin-left: auto;
  }

  .doc-upload-area {
    width: 85%;
  }

  .page-container {
    padding: 30px 20px;
    margin: 10px 0px;
  }

  .fs-20 {
    font-size: 16px !important;
  }
}

.rf-label {
    --label-success: #43B96F;
    --label-success-bg: #ECFFF1;
    --label-pending: #0057B7;
    --label-pending-bg: #F1FAFF;
    --label-reject: #DA7C2A;
    --label-reject-bg: #FFF5F2;
    --label-info: #495057;
    --label-info-bg: #f5f5fc;

    width: 100%;
    text-align: center;
    font-family: inherit;
    font-weight: 400;
    font-size: 12px;
    padding: 10px;
}

.rf-label--success {
    color: var(--label-success);
    background-color: var(--label-success-bg);
}
.rf-label--pending {
    color: var(--label-pending);
    background-color: var(--label-pending-bg);
}
.rf-label--reject {
    color: var(--label-reject);
    background-color: var(--label-reject-bg);
}
.rf-label--info {
    color: var(--label-info);
    background-color: var(--label-info-bg);
}
.rf-unstyled-button-root {
  cursor: pointer;
  border: 0;
  padding: 0;
  -webkit-appearance: none;
          appearance: none;
  font-size: inherit;
  background-color: transparent;
  text-align: left;
  color: inherit;
  text-decoration: none;
  box-sizing: border-box;

  -webkit-tap-highlight-color: transparent; 
}

.rf-unstyled-button-root:focus {
  outline: 1px solid transparent;
  outline-offset: 1px;
}
.rf-unstyled-button-root:focus-visible {
  border: 1px solid var(--clr-brand-default);
  /* TODO: Check can we do it using variables */
  box-shadow: 0px 0px 0px 2px #0057b740;
  transition-property: box-shadow;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.rf-icon-button-root {
    /* TODO: Implement size & variant (maybe) */

    --clr-white: var(--clr-pure-white);
    --clr-disabled-bg: var(--clr-grey-dark);

    --clr-primary-default: var(--clr-brand-default);
    --clr-primary-light: var(--clr-brand-light);
    --clr-primary-dark: var(--clr-brand-dark);

    --clr-danger-default: var(--clr-info-error-default);
    --clr-danger-light: var(--clr-info-error-light);
    --clr-danger-dark: var(--clr-info-error-dark);

    --clr-success-default: var(--clr-info-success-default);
    --clr-success-light: var(--clr-info-success-light);
    --clr-success-dark: var(--clr-info-success-dark);

    cursor: pointer;
    -webkit-appearance: none;
            appearance: none;
    font-size: 16px;
    position: relative;
    border: 1px solid transparent;
    border-radius: 8px;
    line-height: 1;
    background-color: transparent;
}

.rf-icon-button-root.variant-light {
    background-color: var(--clr-grey-light);
}

.rf-icon-button-root:active {
    -webkit-transform: translateY(1px);
            transform: translateY(1px);
  }

.rf-icon-button-root.loading {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.9;
}
.rf-icon-button-root:disabled {
    cursor: not-allowed;
    color: var(--clr-disabled-bg) !important;
}

.rf-icon-button-root.color-brand {
    color: var(--clr-primary-default);
}
.rf-icon-button-root:hover.color-brand {
    color: var(--clr-primary-dark);
}
.rf-icon-button-root:hover.color-brand.variant-light {
    background-color: var(--clr-primary-light);
}
.rf-icon-button-root.color-success {
    color: var(--clr-success-default);
}
.rf-icon-button-root:hover.color-success {
    color: var(--clr-success-dark);
}
.rf-icon-button-root:hover.color-success.variant-light {
    background-color: var(--clr-success-light);
}
.rf-icon-button-root.color-danger {
    color: var(--clr-danger-default);
}
.rf-icon-button-root:hover.color-danger {
    color: var(--clr-danger-dark);
}
.rf-icon-button-root:hover.color-danger.variant-light {
    background-color: var(--clr-danger-light);
}
.rf-icon-button-root.size-md,
.rf-icon-button-root.size-sm {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}
.rf-icon-button__inner {
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 0;
}
.rf-icon-button__inner.spacing-compact {
    padding: 4px;
}
.rf-icon-button__inner.spacing-no-padding {
    padding: 0;
}

.rf-input__wrapper {
  --clr-danger: var(--clr-info-error-default);
  --clr-warning: var(--clr-info-alert-dark);

  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: var(--clr-text-secondary);
}

.rf-input__label {
  display: inline-block;
  font-size: 14px;
  word-break: break-word;
  cursor: default;

  margin-bottom: 6px;
}
.rf-input__label-required {
  color: var(--clr-danger);
}

.rf-input__container {
  position: relative;
}

.rf-input__container__left-section {
  pointer-events: none;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
}
.rf-input__container__right-section {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
}

.rf-input__container__input.left-section {
  padding-left: 36px;
}
.rf-input__container__input.right-section {
  padding-right: 32px;
}

.rf-input__container__input {
  line-height: 24px;
  -webkit-appearance: none;
          appearance: none;
  resize: none;
  font-size: inherit;
  width: 100%;
  color: var(--clr-text-primary);
  display: block;
  text-align: left;
  padding: 10px 14px;
  border-radius: 8px;
  border: 1px solid var(--clr-grey-default);
  background-color: var(--clr-pure-white);
  transition: border-color 100ms ease 0s;
}

.rf-input__container__input[type="number"]::-webkit-inner-spin-button,
.rf-input__container__input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* INFO: Hack to remove browsers default autofill color */
.rf-input__container__input:-webkit-autofill,
.rf-input__container__input:-webkit-autofill:hover,
.rf-input__container__input:-webkit-autofill:focus,
.rf-input__container__input:-webkit-autofill:active,
.rf-input__container__input:-internal-autofill-selected {
  -webkit-transition: background-color 2000s 0s, color 2000s 0s;
  transition: background-color 2000s 0s, color 2000s 0s;
}

.rf-input__container__input::-webkit-input-placeholder {
  -webkit-user-select: none;
          user-select: none;
  color: var(--clr-grey-default);
}

.rf-input__container__input::placeholder {
  -webkit-user-select: none;
          user-select: none;
  color: var(--clr-grey-default);
}

.rf-input__container__input:hover {
  border-color: var(--clr-grey-dark);
}

.rf-input__container__input:disabled {
  cursor: not-allowed;
  color: var(--clr-grey-default);
  background-color: var(--clr-grey-light);
}
.rf-input__container__input:hover:disabled {
  border-color: var(--clr-grey-default);
}

.rf-input__container__input:focus {
  outline: 1px solid transparent;
  outline-offset: 1px;
}
.rf-input__container__input:focus-visible {
  border: 1px solid var(--clr-brand-default);
  /* TODO: Check can we do it using variables */
  box-shadow: 0px 0px 0px 2px #0057b740;
  transition-property: box-shadow;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.rf-input__error,
.rf-input__warning,
.rf-input__hint {
  font-size: 14px;
  text-decoration: none;
  word-break: break-word;
  margin-top: 6px;
}

.rf-input__error,
.error .rf-input__label,
.error .rf-input__container__right-section {
  color: var(--clr-danger);
}

.error .rf-input__container__input {
  border-color: var(--clr-danger);
}

.rf-input__warning,
.warning .rf-input__container__right-section {
  color: var(--clr-warning);
}

.warning .rf-input__container__input {
  border-color: var(--clr-warning);
}


/* TODO: This is temp, need to fix later */
.rf-search-input {
  border: none;
  background-color: var(--clr-grey-light);
  font-size: 14px;
  line-height: 20px;
  padding-top: 6px;
  padding-bottom: 6px;
  width: 280px;
}

.rf-search-input:focus {
  border: none;
}

.amount-deducted-tab {
  color: var(--green);
  background-color: var(--lightGreen);
}

.amount-repaid-tab {
  background-color: var(--clr-info-success-light) !important;
  color: var(--clr-info-success-dark) !important;
}

.amount-transfered-tab {
  background-color: var(--clr-info-highlight-light) !important;
  color: var(--clr-info-highlight-dark) !important;
}

.modal{background-color:rgba(0,0,0,.5);display:none;z-index:100;padding-top:40px}.modal .modal-dialog{max-width:650px}.modal .modal-dialog.modal-x-lg{width:90% !important}.modal .modal-body{max-height:85vh;overflow-y:auto;overflow-x:hidden;padding:10px}.modal .form-modal-container{display:flex;flex-wrap:wrap}.modal .form-modal-container .form-group{flex:1 1;flex-basis:50%;padding:0px 5px}.modal .form-modal-container .form-group.full-width{flex-basis:100%}.close-btn{border:1px solid var(--primary);color:var(--primary);border-radius:3px;cursor:pointer;background-color:#fff;padding:5px 10px}.submit-btn-modal{border:none;background:var(--primary);border-radius:3px;cursor:pointer;color:#fff;padding:5px 10px}
.rf-radio-group__root {
  --clr-danger: var(--clr-info-error-default);
  --clr-warning: var(--clr-info-alert-dark);

  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: var(--clr-text-secondary);
}

.rf-radio-group__label {
  display: inline-block;
  font-size: 14px;
  word-break: break-word;
  cursor: default;

  margin-bottom: 6px;
}
.rf-radio-group__label-required {
  color: var(--clr-danger);
}

.rf-radio-group__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  min-height: 46px;
}

.rf-radio-group__error {
  font-size: 14px;
  text-decoration: none;
  word-break: break-word;
  margin-top: 6px;
  color: var(--clr-danger);
}

.rf-radio__wrapper {
  --clr-white: var(--clr-pure-white);
  --clr-text: var(--clr-text-primary);
  --clr-brand: var(--clr-brand-default);
  --clr-disabled: var(--clr-grey-default);
  --clr-disabled-text: var(--clr-text-tertiary);

  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.rf-radio__element {
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--clr-white);
  margin: 0;
  font: inherit;
  border: 1.5px solid var(--clr-brand);
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-property: background-color, border-color;
  transition-timing-function: ease;
  transition-duration: 100ms;
  cursor: default;
}

.rf-radio__element:disabled {
  border: 1.5px solid var(--clr-disabled);
  cursor: not-allowed;
}

.rf-radio__element::before {
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 50%;
  -webkit-transform: scale(0);
          transform: scale(0);
  transition: 120ms -webkit-transform ease-in-out;
  transition: 120ms transform ease-in-out;
  transition: 120ms transform ease-in-out, 120ms -webkit-transform ease-in-out;
  box-shadow: inset 12px 12px var(--clr-brand);
}

.rf-radio__element:disabled:before {
  box-shadow: inset 12px 12px var(--clr-disabled);
}

.rf-radio__element:checked:before {
  -webkit-transform: scale(1);
          transform: scale(1);
}

.rf-radio__element:focus {
  border: 1px solid var(--clr-brand);
  /* TODO: Check can we do it using variables */
  box-shadow: 0px 0px 0px 2px #0057b740;
  transition-property: box-shadow;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.rf-radio__label {
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: var(--clr-text);
  padding-left: 10px;
  cursor: default;
  margin: 0;
}

.rf-radio__label.disabled {
  color: var(--clr-disabled-text);
  pointer-events: none;
}

.rf-content__root {
    height: 100%;
    width: 100%;
}
.rf-content__root.fixed {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

.rf-content__header {
    padding: 24px 40px;
    /* TODO: Make it using variables */
    box-shadow: 0px 2px 2px rgba(21, 23, 26, 0.05);
    overflow-x: hidden;
}
.rf-content__header.fixed {
    flex-shrink: 0;
}

.rf-content__main {
    padding: 40px;
}
.rf-content__main.fixed {
    flex-grow: 1;
    overflow: auto;
    position: relative;
    /* min-height: 200px; */
}

.rf-content__footer {
    padding: 24px 40px;
    /* TODO: Make it using variables */
    box-shadow: 0px -2px 2px rgba(21, 23, 26, 0.05);
    overflow-x: hidden;
}
.rf-content__footer.fixed {
    flex-shrink: 0;
}
.rf-button-root {
  --clr-white: var(--clr-pure-white);
  --clr-disabled-bg: var(--clr-grey-dark);
  --clr-disabled-text: var(--clr-text-secondary);

  --clr-primary-default: var(--clr-brand-default);
  --clr-primary-light: var(--clr-brand-light);
  --clr-primary-dark: var(--clr-brand-dark);

  --clr-danger-default: var(--clr-info-error-default);
  --clr-danger-light: var(--clr-info-error-light);
  --clr-danger-dark: var(--clr-info-error-dark);

  --clr-success-default: var(--clr-info-success-default);
  --clr-success-light: var(--clr-info-success-light);
  --clr-success-dark: var(--clr-info-success-dark);

  border-radius: 8px;
  border: 1px solid transparent;
  font-weight: 600;
  position: relative;
  line-height: 24px;
  font-size: 16px;
  -webkit-user-select: none;
          user-select: none;
}

.rf-button-root:active {
  -webkit-transform: translateY(1px);
          transform: translateY(1px);
}
.rf-button-root:active:disabled {
  -webkit-transform: none;
          transform: none;
}
.rf-button-root:disabled {
  border-color: transparent;
  color: var(--clr-disabled-text) !important;
  cursor: not-allowed;
}
.rf-button-root.loading {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.9;
}

/* Primary */

.rf-button-root.variant-primary.color-brand {
  color: var(--clr-white);
  background-color: var(--clr-brand-default);
  border-color: var(--clr-brand-default);
}
.rf-button-root.variant-primary.color-danger {
  color: var(--clr-white);
  background-color: var(--clr-danger-default);
  border-color: var(--clr-danger-default);
}
.rf-button-root.variant-primary.color-success {
  color: var(--clr-white);
  background-color: var(--clr-success-default);
  border-color: var(--clr-success-default);
}

.rf-button-root:hover.variant-primary.color-brand {
  background-color: var(--clr-brand-dark);
  border-color: var(--clr-brand-dark);
}
.rf-button-root:hover.variant-primary.color-danger {
  background-color: var(--clr-danger-dark);
  border-color: var(--clr-danger-dark);
}
.rf-button-root:hover.variant-primary.color-success {
  background-color: var(--clr-success-dark);
  border-color: var(--clr-success-dark);
}

.rf-button-root:disabled.variant-primary {
  background-color: var(--clr-grey-dark) !important;
  border-color: var(--clr-grey-dark) !important;
}

/* Secondary */

.rf-button-root.variant-secondary {
  background-color: var(--clr-white);
}

.rf-button-root.variant-secondary.color-brand {
  color: var(--clr-brand-default);
  border-color: var(--clr-brand-default);
}
.rf-button-root.variant-secondary.color-danger {
  color: var(--clr-danger-default);
  border-color: var(--clr-danger-default);
}
.rf-button-root.variant-secondary.color-success {
  color: var(--clr-success-default);
  border-color: var(--clr-success-default);
}

.rf-button-root:hover.variant-secondary.color-brand {
  color: var(--clr-brand-dark);
  border-color: var(--clr-brand-dark);
}
.rf-button-root:hover.variant-secondary.color-danger {
  color: var(--clr-danger-dark);
  border-color: var(--clr-danger-dark);
}
.rf-button-root:hover.variant-secondary.color-success {
  color: var(--clr-success-dark);
  border-color: var(--clr-success-dark);
}

.rf-button-root:disabled.variant-secondary {
  border-color: var(--clr-grey-dark) !important;
}

/* Text */

.rf-button-root.variant-text {
  background-color: var(--clr-white);
}

.rf-button-root.variant-text.color-brand {
  color: var(--clr-brand-default);
}
.rf-button-root.variant-text.color-danger {
  color: var(--clr-danger-default);
}
.rf-button-root.variant-text.color-success {
  color: var(--clr-success-default);
}

.rf-button-root:hover.variant-text.color-brand {
  color: var(--clr-brand-dark);
}
.rf-button-root:hover.variant-text.color-danger {
  color: var(--clr-danger-dark);
}
.rf-button-root:hover.variant-text.color-success {
  color: var(--clr-success-dark);
}

/* Size */
.rf-button-root.size-sm {
  height: 32px;
  min-width: 130px;
  font-weight: 500;
  line-height: 20px;
  font-size: 14px;
}
.rf-button-root.size-md {
  height: 48px;
  font-weight: 500;
  line-height: 20px;
  font-size: 14px;
  min-width: 154px;
}
.rf-button-root.size-lg {
  height: 56px;
  min-width: 271px;
}

/* Spacing */
.rf-button-root.spacing-regular {
  padding: 0 16px;
}
.rf-button-root.spacing-compact {
  padding: 0 12px;
}
.rf-button-root.spacing-full-width {
  width: 100%;
}

.rf-button__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: visible;
}

.rf-button__label {
  white-space: nowrap;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.rf-button__left-section {
  margin-right: 12px;
  height: 24px;
  width: 24px;
  transition: 0.4s ease-in-out;
}
.rf-button__right-section {
  margin-left: 12px;
  height: 24px;
  width: 24px;
  transition: 0.4s ease-in-out;
}

/* TODO: Handle for small buttons */
.rf-button-root:hover .rf-button__left-section {
  -webkit-transform: translateX(-8px);
          transform: translateX(-8px);
}
.rf-button-root:hover .rf-button__right-section {
  -webkit-transform: translateX(8px);
          transform: translateX(8px);
}



.search-input {
  display: block;
  width: 100%;
  border: none;
  padding: 12px;
  border-radius: 5px;
}
.search-input::-webkit-input-placeholder {
  color: #a5a5a5;
}
.search-input::placeholder {
  color: #a5a5a5;
}

.rdt_TableHeader {
  padding-left: 0px !important;
}

.doc-card {
  width: 100%;
  height: 150px;
  border-radius: 5px;
  position: relative;
  box-shadow: 0px 0px 10px gainsboro;
}

.doc-card img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
  object-position: center;
}

.absolute-top-left-btn {
  position: absolute;
  top: 5px;
  left: 5px;
  background-color: grey;
  color: white;
  font-size: 10px;
  padding: 3px 10px;
  border-radius: 5px;
}
.absolute-top-right-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: grey;
  color: white;
  font-size: 10px;
  padding: 3px 10px;
  border-radius: 5px;
}

/* This are TEMP - Hack - Fix it later with proper js */
.rf-table th:nth-child(2), .rf-table td:nth-child(2) {
  position: -webkit-sticky;
  position: sticky;
  left: 40px;
  z-index: 11;
}
.rf-table th:nth-child(3), .rf-table td:nth-child(3) {
  position: -webkit-sticky;
  position: sticky;
  left: 236px;
  z-index: 11;
}

.rf-overlay__root {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 400;
}

.rf-overlay__blur {
  position: absolute;
  inset: 0px;
  z-index: 1000;
}

.rf-drawer__root {
  position: fixed;
  z-index: 200;
  inset: 0px;
}

.rf-drawer__container {
  max-width: 100%;
  max-height: 100vh;
  position: fixed;
  outline: 0;
  z-index: 1;
  background-color: var(--clr-pure-white);
}

.rf-drawer__body {
  width: 100%;
  height: 100%;
  overflow: auto;
}

/* TODO: Work on transitions */
.rf-drawer__overlay {
    transition-property: opacity;
    transition-duration: 125ms;
    transition-timing-function: ease;
    opacity: 1;
}

.rf-drawer__close {
    position: absolute;
}
.rf-checkbox__wrapper {
  --checkbox-width: 20px;
  --checkbox-bg-clr: var(--clr-pure-white);
  --checkbox-border-clr: var(--clr-grey-default);
  --checkbox-checked-bg-clr: var(--clr-brand-default);
  --checkbox-font-size: 14px;
  --checkbox-font-line-height: 20px;
  --checkbox-font-weight: 500;

  display: flex;
  align-items: center;
}

.rf-checkbox__inner {
  position: relative;
  width: var(--checkbox-width);
  height: var(--checkbox-width);
}
.rf-checkbox__inner-size-sm {
  --checkbox-width: 16px;
}
.rf-checkbox__inner-position-left {
  order: 2;
}
.rf-checkbox__inner-position-right {
  order: 1;
}

.rf-checkbox__input {
  -webkit-appearance: none;
          appearance: none;
  background-color: var(--checkbox-bg-clr);
  border: 1px solid var(--checkbox-border-clr);
  width: var(--checkbox-width);
  height: var(--checkbox-width);
  border-radius: 4px;
  display: block;
  padding: 0;
  margin: 0;
  transition: border-color 100ms ease, background-color 100ms ease;
  cursor: pointer;
}

.rf-checkbox__input:focus {
  border: 1px solid var(--clr-brand-default);
  box-shadow: 0px 0px 0px 4px var(--clr-brand-light);
  transition-property: box-shadow;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

/* TODO: Figure indeterminate */
.rf-checkbox__inner-icon {
  color: var(--checkbox-bg-clr);

  pointer-events: none;
  width: calc(var(--checkbox-width) / 2);
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  margin: auto;
}

/* TODO: Clear about it's usecase
.rf-checkbox__input-indeterminate + .rf-checkbox__inner-icon {
    color: inherit;
    opacity: 1;
} */

.rf-checkbox__input:checked {
  background-color: var(--checkbox-checked-bg-clr);
  border-color: var(--checkbox-checked-bg-clr);
}

.rf-checkbox__input:checked + .rf-checkbox__inner-icon {
  opacity: 1;
  color: var(--clr-pure-white);
  -webkit-transform: translateY(0) scale(1);
          transform: translateY(0) scale(1);
}

.rf-checkbox__input:hover:not(:disabled) {
  --checkbox-bg-clr: var(--clr-brand-light);
  --checkbox-border-clr: var(--clr-brand-default);
}

.rf-checkbox__input:hover:checked:not(:disabled) {
  --checkbox-bg-clr: var(--clr-brand-dark);
}

.rf-checkbox__input:disabled {
  background-color: var(--clr-grey-light);
  border-color: var(--checkbox-border-clr);
  cursor: not-allowed;
}

.rf-checkbox__input:disabled + .rf-checkbox__inner-icon {
  color: var(--checkbox-border-clr);
}

.rf-checkbox__label {
  font-family: inherit;
  font-size: var(--checkbox-font-size);
  font-weight: var(--checkbox-font-weight);
  line-height: var(--checkbox-font-line-height);
  color: var(--clr-text-primary);
  margin: 0;
}

.rf-checkbox__label-position-left {
  order: 1;
  padding-right: 8px;
}
.rf-checkbox__label-position-right {
  order: 2;
  padding-left: 8px;
}

.rf-tooltip__container {
  font-family: inherit;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;

  position: absolute;
  padding: 8px;
  background-color: var(--clr-text-primary);
  color: var(--clr-pure-white);
  border-radius: 4px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  z-index: 99;
}

.rf-tooltip__arrow {
  position: absolute;
  background: var(--clr-text-primary);
  width: 8px;
  height: 8px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.sortable-icon {
  display: inline-block;
  height: 11px;
  position: relative;
  width: 10px;
}

.sortable-icon:before,
.sortable-icon:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  content: "";
  left: 0;
  position: absolute;
}

.sortable-icon:before {
  border-bottom: 4px solid var(--clr-grey-dark);
}

.sortable-icon:after {
  border-top: 4px solid var(--clr-grey-dark);
  bottom: 0;
}

.sorted-by-ascending.sortable-icon::before {
  border-bottom: 4px solid var(--clr-text-primary);
}
.sorted-by-descending.sortable-icon::after {
  border-top: 4px solid var(--clr-text-primary);
}

.rf-table__container {
  background-color: var(--clr-pure-white);
  border-radius: 12px;
}

.rf-table__header {
  padding: 32px 32px 24px 18px;
}
.rf-table__footer {
  padding: 24px 32px 24px 18px;
}
.rf-table__wrapper {
  padding: 0;
  width: auto;
  overflow-x: auto;
}

.rf-table {
  border-collapse: collapse;
  border-spacing: 0;
  min-width: 100%;
}

.rf-table thead th {
  background-color: var(--clr-grey-light);
  color: var(--clr-text-secondary);
}
.rf-table tbody tr {
    background-color: var(--clr-pure-white);
}
.rf-table tbody td {
  background-color: inherit;
  color: var(--clr-text-primary);
}
.rf-table th,
.rf-table td {
  outline: 0;
  vertical-align: middle;
  text-align: left;
  padding: 8px;
}
.rf-table th {
  font-family: inherit;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}
.rf-table td {
  height: 52px;
  font-family: inherit;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

.rf-table tbody tr:not(:last-child) {
  border-bottom: 1px solid #dadbdc;
}
.rf-table__wrapper.has-footer .rf-table tbody tr:last-child {
  border-bottom: 1px solid #dadbdc;
}

.rf-table tbody tr:focus-within,
.rf-table tbody tr:hover {
  background-color: #EBF5FF;
}

.rf-table tbody tr.selected td {
  background-color: var(--clr-brand-light);
}

/* Global max-width check with Design */
/* .rf-table td,
.rf-table th {
  max-width: 200px;
} */

.rf-table__table-header-cell,
.rf-table__table-data-cell {
  display: flex;
  min-width: 0;
  align-items: center;
}

.rf-table__table-header-cell__label,
.rf-table__table-data-cell__label {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 5px;
}

.rf-table__table-header-cell__actions,
.rf-table__table-data-cell__actions {
  margin-left: 8px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

.rf-table__table-data-cell__actions {
  margin-left: 6px;
  display: flex;
  visibility: hidden;
  align-items: center;
}

.rf-table tbody tr:focus-within .rf-table__table-data-cell__actions.visible-hover,
.rf-table tbody tr:hover .rf-table__table-data-cell__actions.visible-hover,
.rf-table__table-data-cell__actions.visible-always {
  visibility: visible;
}

.rf-table__table-cell__checkbox {
  padding-left: 16px !important;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 99;
  width: 40px;
}

.rf-tag__wrapper {
  --clr-bg: var(--clr-brand-default);
  --clr-content: var(--clr-pure-white);

  -webkit-tap-highlight-color: transparent;
  font-family: inherit;
  font-size: 12px;
  height: 20px;
  line-height: 16px;
  text-decoration: none;
  padding: 0px 8px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  border-radius: 4px;
  font-weight: 500;
  cursor: inherit;
  text-overflow: ellipsis;
  overflow: hidden;
  background-color: var(--clr-bg);
  color: var(--clr-content);
}

.rf-tag__color-alert {
  --clr-bg: var(--clr-info-alert-light);
  --clr-content: var(--clr-info-alert-dark);
}
.rf-tag__color-success {
  --clr-bg: var(--clr-info-success-light);
  --clr-content: var(--clr-info-success-dark);
}
.rf-tag__color-grey {
  --clr-bg: var(--clr-grey-light);
  --clr-content: var(--clr-text-secondary);
}
.rf-tag__color-white {
  --clr-bg: var(--clr-pure-white);
  --clr-content: var(--clr-text-primary);
}
.rf-tag__color-highlight {
  --clr-bg: var(--clr-info-highlight-light);
  --clr-content: var(--clr-info-highlight-dark);
}
.rf-tag__color-error {
  --clr-bg: var(--clr-info-error-light);
  --clr-content: var(--clr-info-error-dark);
}

.rf-tag__dot-left::before,
.rf-tag__dot-right::after {
  content: "";
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 2px;
  background-color: var(--clr-content);
}

.rf-tag__dot-left::before {
  margin-right: 8px;
}
.rf-tag__dot-right::after {
  margin-left: 8px;
}

.rf-tag__full-width {
  width: 100%;
}

.rf-tag__rounded {
  border-radius: 16px;
}

.rf-tag__inner {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rf-tag__left {
  margin-right: 8px;
}
.rf-tag__right {
  margin-left: 8px;
}

.rf-pagination {
    display: flex;
    align-items: center;
    margin: 10px 0;

    font-family: inherit;
    font-size: 12px;
    line-height: 16px;
    color: var(--clr-text-primary);
}

.rf-pagination__list {
    list-style: none;
    display: flex;
    align-items: center;

    margin: 0;
    padding: 0;
}

.rf-pagination__item {
    -webkit-user-select: none;
            user-select: none;
}

.rf-pagination__item:not(:last-child) {
    margin-right: 8px;
}

.rf-pagination__btn {
    font-weight: 500;

    background: none;
    padding: 2px 8px;
    color: var(--clr-brand-default);
    border: 1px solid transparent;
    border-radius: 6px;
    transition: border-color .2s cubic-bezier(0.3, 0, 0.5, 1);
}
.rf-pagination__btn:hover {
    border-color: var(--clr-brand-default);
    outline: 0;
    transition-duration: .1s;
}
.rf-pagination__btn.active {
    color: #fff;
    background-color: var(--clr-brand-default);
    border-color: transparent;
    pointer-events: none;
}

.rf-pagination__btn--action {
    font-weight: 500;

    background: none;
    max-width: 32px;
    padding: 2px 8px;
    color: var(--clr-brand-default);
    border: none
}

.rf-pagination__btn--action:disabled {
    color: var(--clr-text-tertiary);
}

.rf-pagination__jump-to {
    display: flex;
    align-items: center;
    font-size: 13px;
    margin-left: 20px;
}

.rf-pagination__jump-to--text {
    margin: 0;
    margin-right: 10px;
}

.rf-pagination__jump-to--input {
    width: 36px;
    height: 20px;
    padding: 2px 6px;
    border: 1px solid var(--clr-text-primary);
    border-radius: 6px;
    -webkit-appearance: none;
            appearance: none;

}
.rf-pagination__jump-to--input::-webkit-inner-spin-button, 
.rf-pagination__jump-to--input::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
.popover__wrapper {
  position: relative;
}

.popover-dropdown {
  position: absolute;
  z-index: 99;
  right: 0;
  left: auto;
  top: 100%;
  cursor: default;
  display: inline-block;
  vertical-align: top;
  padding: 8px;
  margin: 8px 0 0;
  width: auto;
  min-width: 140px;
  max-width: 720px;
  max-height: 330px;
  overflow-x: hidden;
  overflow-y: auto;
  background: var(--clr-pure-white);
  border-radius: 16px;
  box-shadow: 0px 5px 20px rgba(21, 23, 26, 0.12);
}

.popover-dropdown.popover-dropdown-loc-bottom-right {
  right: auto;
  left: 0;
}
.popover-dropdown.popover-dropdown-loc-bottom-center {
  right: auto;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.rf-filter__wrapper {
    padding: 12px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: var(--clr-text-secondary);
}

.rf-filter__title {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: var(--clr-text-primary);
}

.rf-filter__row-container {
    display: grid;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
    grid-template-columns: 1fr 2fr 1fr 2fr 20px;
    align-items: center;
    margin-bottom: 16px;
}

.rf-filter__row-item__close {
    grid-column-end: 6;
}


/* HACK to override select style for now */
/* Will migrate to custom select elemnent later, then this override will not be required */
.rf-filter__wrapper .rf-input__container__input {
    padding: 6px 10px;
    font-size: 14px;
    line-height: 20px;
}
.rf-filter__wrapper .rf-input__container__right-section {
    width: 24px;
}
.rf-filter__wrapper .rf-input__container__input.right-section {
    padding-right: 24px;
}
.rf-switch__wrapper {
  --switch-clr: var(--clr-pure-white);
  --switch-bg-clr: var(--clr-grey-default);
  --switch-height: 24px;
  --switch-track-width: 44px;
  --switch-font-size: 16px;
  --switch-font-weight: 400;
  --switch-thumb-width: 20px;
  --switch-track-padding: 2px;
  --switch-transition-duration: 150ms;

  --switch-label-font-size: 16px;
  --switch-label-font-weight: 400;
  --switch-label-line-height: 24px;
  --switch-description-font-size: 14px;
  --switch-description-font-weight: 400;
  --switch-description-line-height: 20px;

  display: inline-flex;
  align-items: center;
}

.rf-switch__wrapper.full-width {
  width: 100%;
  justify-content: space-between;
}

.rf-switch__wrapper-size-sm {
    --switch-height: 20px;
    --switch-track-width: 36px;
    --switch-thumb-width: 16px;
    --switch-label-font-size: 14px;
    --switch-description-font-size: 12px;
  }

.rf-switch__inner {
  position: relative;
}

.rf-switch__inner-position-left {
  order: 2;
}
.rf-switch__inner-position-right {
  order: 1;
}

.rf-switch__input {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.rf-switch__track {
  overflow: hidden;
  position: relative;
  border-radius: 12px;
  background-color: var(--switch-bg-clr);
  border: 1px solid var(--switch-bg-clr);
  height: var(--switch-height);
  min-width: var(--switch-track-width);
  margin: 0;
  -webkit-appearance: none;
          appearance: none;
  display: flex;
  align-items: center;
  font-size: var(--switch-font-size);
  font-weight: var(--switch-font-weight);
  -webkit-user-select: none;
          user-select: none;
  z-index: 0;
  line-height: 0;
  color: var(--switch-clr);
  transition: color var(--switch-transition-duration) ease;
  cursor: pointer;
}

.rf-switch__input:checked + .rf-switch__track {
  --switch-bg-clr: var(--clr-brand-default);
}

.rf-switch__input:disabled + .rf-switch__track {
  --switch-bg-clr: var(--clr-grey-light);
  cursor: not-allowed;
}

.rf-switch__thumb {
  position: absolute;
  z-index: 1;
  border-radius: 50%;
  display: flex;
  background-color: var(--clr-pure-white);
  height: var(--switch-thumb-width);
  width: var(--switch-thumb-width);
  border: 1px solid var(--clr-pure-white);
  left: var(--switch-track-padding);
  transition: left var(--switch-transition-duration) ease;
}

.rf-switch__thumb > * {
  margin: auto;
}

.rf-switch__input:checked + * > .rf-switch__thumb {
  left: calc(100% - var(--switch-thumb-width) - var(--switch-track-padding));
}

.rf-switch__track-label {
  height: 100%;
  display: grid;
  place-content: center;
  min-width: calc(var(--switch-track-width) - var(--switch-thumb-width));
  /* TODO: Change this based on size */
  padding-inline: 8px;
  margin: 0 0 0 calc(var(--switch-thumb-width) + var(--switch-track-padding));
  transition: margin var(--switch-transition-duration) ease;
}

.rf-switch__input:checked + * > .rf-switch__track-label {
  margin: 0 calc(var(--switch-thumb-width) + var(--switch-track-padding)) 0 0;
}

.rf-switch__label-wrapper {
  display: flex;
  flex-direction: column;
}
.rf-switch__label-wrapper-position-left {
  order: 1;
  padding-right: 8px;
  text-align: right;
}
.rf-switch__label-wrapper-position-right {
  order: 2;
  padding-left: 8px;
}

.rf-switch__label {
  font-family: inherit;
  font-size: var(--switch-label-font-size);
  font-weight: var(--switch-label-font-weight);
  line-height: var(--switch-label-line-height);
  color: var(--clr-text-primary);
  margin: 0;
}

.rf-switch__label-description {
  font-family: inherit;
  font-size: var(--switch-description-font-size);
  font-weight: var(--switch-description-font-weight);
  line-height: var(--switch-description-line-height);
  color: var(--clr-text-secondary);
}

.data-field {
  color: var(--primary);

  display: flex;
  /* align-items: center; */
}

.data-field--label {
  color: #a7a7a7;
  font-size: 14px;
  font-weight: 400;
}

.data-field--value {
  color: inherit;
  font-size: 22px;
  font-weight: 600;
}
.data-field--value.clickable {
  cursor: pointer;
  color: var(--secondary);
}

.data-field--icon {
  max-height: 50px;
  margin-right: 20px;
}

.notification-box {
  padding: 20px 30px;
  border-left: 12px solid;
  border-radius: 8px;
  background-color: #ffffff;
  color: var(--primary);
}

.notification-box__message {
  font-size: 22px;
  font-weight: 600;
  margin: 10px 0;
}

.notification-box__description {
  font-size: 15px;
  font-weight: 400;
}

.notification-box__data-column-container {
  margin-top: 20px;
  margin-bottom: 20px;
}

.notification-box__data-column:not(:last-child) {
  border-right: 1px solid #dedede;
}

.notification-box__data-column--label {
  color: #a7a7a7;
  font-size: 14px;
  font-weight: 400;
}

.notification-box__data-column--value {
  color: inherit;
  font-size: 23px;
  font-weight: 600;
}

.notification-box__cta-btn {
  margin: 10px 0;
  font-size: 15px;
  font-weight: 500;
  color: #ffffff;
  background-color: var(--secondary);
  padding: 10px 20px;
}
.notification-box__cta-btn:hover {
  color: #ffffff;
  background-color: var(--secondary);
  opacity: 0.8;
}

.tab-bar {
    list-style: none;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    -webkit-user-select: none;
            user-select: none;

    color: #A7A7A7;
    font-size: 14px;
    font-weight: 400;
}

.tab-bar__tab {
    padding: 12px 45px;
    background-color: #E8E8E8;;
    box-shadow: 3px 3px 10px #00000029;
    border: 1px solid #E8E8E8;
    border-radius: 8px 8px 0px 0px;
    cursor: pointer;
}
.tab-bar__tab.active {
    color: #fff;
    background-color: var(--secondary);
    box-shadow: 3px 3px 10px #00000029;
    border: 1px solid var(--secondary);
    font-weight: 500;
    pointer-events: none;
}
.tab-bar__tab.disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.8;
}
.table-comp {
    --table-header-bg-color: #FAFAFA;
    --table-border-color: #E6E6E6;
    --table-link-color: var(--secondary);
}

.table-comp table {
    font: inherit;
    font-size: 14px;
    font-weight: 400;
    color: var(--primary);
}

.table-comp thead {
    background-color: var(--table-header-bg-color);
}

.table-comp td, .table-comp th {
    padding: 20px;
}
.table-comp tbody tr:not(:last-child) {
    border-bottom: 1px solid var(--table-border-color)
}

.table-comp .table-link {
    color: var(--table-link-color);
    font-weight: 500;
    text-decoration: none;
}
.info-bar {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px 30px;

  display: flex;
  align-items: center;
  justify-content: space-around;
}

.info-bar__data-container {
  display: flex;
  align-items: center;
}

.info-bar__data-container--label {
  margin: 0;
  margin-right: 15px;
  color: #a7a7a7;
  font-size: 12px;
  font-weight: 400;
}

.info-bar__data-container--value {
  margin: 0;
  color: #15171A;
  font-size: 18px;
  font-weight: 600;
}

.payroll-card {
  border-radius: 8px;
  padding: 30px;
  background-color: #fff;
}

.payroll-card.disabled {
  opacity: 0.7;
  pointer-events: none;
}

/* Monthly Summary Component --- Starts --- */

.payroll-monthly-summary {
  background-color: #fff;
  padding: 40px;
  border-radius: 8px;
}

.payroll-monthly-summary.disabled {
  opacity: 0.8;
}
.payroll-monthly-summary.disabled * {
  cursor: default;
  pointer-events: none;
  color: #A7A7A7;
}

.payroll-monthly-summary__action-link--icon {
  margin-right: 8px;
  max-height: 20px;
}

.payroll-monthly-summary__data-filed--row {
  margin-top: 30px;
  margin-bottom: 30px;
}

.payroll-monthly-summary__month-dropdown {
  background: transparent;
  border: none;
}

.payroll-monthly-summary__message-text {
  color: #a7a7a7;
  font-size: 14px;
  font-weight: 400;
}

/* Monthly Summary Component --- Ends --- */


/* Payroll Summary Card --- Starts --- */

.payroll-summary-card__month--text {
  font-weight: 500;
  font-size: 15px;
}

.payroll-summary-card__month--grey-outline-text {
  font-weight: 600;
  font-size: 18px;
  padding: 5px;
  background-color: #F5F5FC;
}

.payroll-summary-card__alert-box {
  background-color: #F5F5FC;
  font-size: 12px;
  font-weight: 600;
  padding: 10px;
  margin: 20px 0;
}

.payroll-summary-card__buttons * {
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.payroll-summary-card__buttons .btn-outline-primary {
  color: var(--secondary);
  border-color: var(--secondary);
}
.payroll-summary-card__buttons .btn-outline-primary:hover {
  color: #fff;
  background-color: var(--secondary);
}
.payroll-summary-card__buttons .btn-primary {
  background-color: var(--secondary);
}

.payroll-summary-card__status_text--success {
  font-size: 12px;
  font-weight: 400;
  color: #3BC88F;
}

.payroll-summary-card__text {
  font-size: 13px;
  font-weight: 500;
}
.payroll-summary-card__amount-text {
  font-size: 16px;
  font-weight: 600;
}

.payroll-summary-card__text--danger {
  color: #F38686;
}

/* Payroll Summary Card --- Ends --- */

.payroll-upload-summary__title {
  font-size: 18px;
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 20px;
}

.payroll-search-bar {
  display: flex;
  align-items: center;
}

.payroll-search-bar--input {
  max-width: 500px;
  margin-right: 20px;

  padding: 22px 20px;
  font-size: 15px;
}

.payroll-search-bar--button {
  padding: 10px 50px;
  font-size: 15px;
}

.search-input {
  display: block;
  width: 100%;
  border: none;
  padding: 12px;
  border-radius: 5px;
}
.search-input::-webkit-input-placeholder {
  color: #a5a5a5;
}
.search-input::placeholder {
  color: #a5a5a5;
}

.rdt_TableHeader {
  padding-left: 0px !important;
}

.user-details {
  display: grid;
  grid-gap: 0.8rem;
  gap: 0.8rem;
  padding: 1rem;
  border: 1px solid var(--clr-grey-default);
  border-radius: 0.8rem;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, 1fr);
}

.user-detail-label {
  font-weight: 400;
  color: var(--clr-text-secondary);
  font-size: 14px;
}

.user-detail-title {
  font-size: inherit;
  font-weight: 600;
}

.user-detail-name {
  margin-bottom: 0.5rem;
  margin-right: 4px;
}

.user-detail-right-border {
  border-right: 1.6px solid var(--clr-grey-default);
}

.user-detail-value {
  font-weight: 600;
  color: var(--clr-text-primary);
  white-space: nowrap;
}

.gap-3 {
  grid-gap: 1rem;
  gap: 1rem;
}

.summary-card{border-radius:8px;background-color:#fff;text-align:center;padding-top:10px}.summary-card .card-logo-wrapper{width:110px;height:110px;display:inline-block;margin:0px auto}.summary-card .logo-img{width:100%;height:100%;object-fit:cover}.summary-card .card-label{display:block;text-transform:capitalize}.summary-card .card-btn{width:100%;border-radius:0 0 8px 8px;padding:15px 0;border:none;background-color:var(--secondary);color:#fff}
.selected-vendor-route{border-bottom:2px solid var(--primary);color:var(--primary)}
.custom-toast {
  position: fixed;
  top: 20px;
  right: 0;
  width: 300px;
  color: white;
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
  height: 50px;
  text-align: center;
  z-index: 99;
}
.ct-open {
  width: 300px;
  -webkit-transition: width .3s ease;
}
.ct-close {
  width: 0;
  -webkit-transition: width .3s ease;
}

.rf-notifier {
  display: flex;
  padding: 25px;
  color: var(--primary);
}

.rf-notifier__icon--container {
  margin-right: 20px;
}

.rf-notifier__icon {
  width: 30px;
  height: 30px;
}

.rf-notifier__icon--wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  /* padding: 10px; */
  background: #f7f7f7;
  border-radius: 9999px;
}

.rf-notifier__title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 1rem;
}

.rf-notifier__message {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 1.5rem;
  white-space: pre-line;
}

.rf-notifier__btn {
  color: var(--secondary);
  font-weight: 500;
}
a:hover {
  text-decoration: none;
  color: inherit;
}

.auth-page-wrapper {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--gray-background-color);
}

.auth-card {
  width: 70%;
  height: calc(100vh - 150px);
  background-color: var(--white);
  border-radius: 5px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.illustartion-box {
  width: 50%;
  height: 100%;
}

.illustartion-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.auth-main-content {
  width: 50%;
  height: 100%;
  padding: 10px 40px 10px 40px;
  display: flex;
  flex-direction: column;
}

.auth-logo {
  width: 130px;
}

.auth-form-wrapper {
  width: 70%;
  margin: 0px auto;
  flex: 14 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.auth-small-card {
  background-color: var(--white);
  border-radius: 8px;
  padding: 40px 75px;
  width: 32%;
}

.eye {
  position: absolute;
  right: 20px;
  top: 50%;
  -webkit-transform: translate(0%, -50%);
          transform: translate(0%, -50%);
}

.bottom-form-detail {
  flex: 1 1;
}
@media only screen and (max-width: 1024px) {
  .illustartion-box {
    display: none;
  }

  .auth-main-content {
    width: 100%;
  }

  .auth-small-card {
    width: 60%;
  }
}

@media only screen and (max-width: 768px) {
  .auth-form-wrapper {
    width: 90%;
  }

  .auth-small-card {
    border-radius: 8px;
    padding: 20px;
    width: 90%;
  }
}

@media only screen and (max-width: 425px) {
  .auth-card {
    width: 95%;
  }

  .auth-main-content {
    padding: 15px;
  }
}

.form-signin{width:100%;max-width:420px;padding:15px;margin:auto}.form-signin-label-group>input,.form-signin-label-group>label{height:3.125rem;padding:.75rem}.form-signin-label-group input::-webkit-input-placeholder{color:transparent}.form-signin-label-group input::placeholder{color:transparent}.form-signin-label-group input:not(:placeholder-shown){padding-top:1.25rem;padding-bottom:.25rem;color:var(--primary) !important}.form-signin-label-group input:not(:placeholder-shown)~label{padding-top:.25rem;padding-bottom:.25rem;font-size:10.6px;color:var(--primary) !important}@supports(-ms-ime-align: auto){.form-signin-label-group>label{display:none}.form-signin-label-group input::-ms-input-placeholder{color:var(--primary) !important}}@media all and (-ms-high-contrast: none),(-ms-high-contrast: active){.form-signin-label-group>label{display:none}.form-signin-label-group input:-ms-input-placeholder{color:var(--primary)}}
.otp-box {
  width: 30px;
  border: none;
  margin: 13px 6px;
  padding: 0px 15px;
  font-size: 34px;
  font-weight: 900;
  color: var(--primary);
  border-bottom: 2px solid var(--lightgray);
}
.otp-text {
  border: none;
  width: 30px;
  margin: 13px 6px;
  background: transparent;
  text-align: center;
  padding: 0px;
  font-size: 34px;
  font-weight: 900;
  border-bottom: 2px solid var(--primary);
  color: var(--primary);
}

.otp-box:focus,
.otp-text:focus {
  outline: none !important;
  box-shadow: none !important;
  border-bottom: 2px solid var(--primary);
}
.m-60 {
  margin: 0px 60px;
}

