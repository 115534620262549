

.search-input {
  display: block;
  width: 100%;
  border: none;
  padding: 12px;
  border-radius: 5px;
}
.search-input::placeholder {
  color: #a5a5a5;
}

.rdt_TableHeader {
  padding-left: 0px !important;
}

.doc-card {
  width: 100%;
  height: 150px;
  border-radius: 5px;
  position: relative;
  box-shadow: 0px 0px 10px gainsboro;
}

.doc-card img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
  object-position: center;
}

.absolute-top-left-btn {
  position: absolute;
  top: 5px;
  left: 5px;
  background-color: grey;
  color: white;
  font-size: 10px;
  padding: 3px 10px;
  border-radius: 5px;
}
.absolute-top-right-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: grey;
  color: white;
  font-size: 10px;
  padding: 3px 10px;
  border-radius: 5px;
}

/* This are TEMP - Hack - Fix it later with proper js */
.rf-table th:nth-child(2), .rf-table td:nth-child(2) {
  position: sticky;
  left: 40px;
  z-index: 11;
}
.rf-table th:nth-child(3), .rf-table td:nth-child(3) {
  position: sticky;
  left: 236px;
  z-index: 11;
}
