.user-details {
  display: grid;
  gap: 0.8rem;
  padding: 1rem;
  border: 1px solid var(--clr-grey-default);
  border-radius: 0.8rem;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, 1fr);
}

.user-detail-label {
  font-weight: 400;
  color: var(--clr-text-secondary);
  font-size: 14px;
}

.user-detail-title {
  font-size: inherit;
  font-weight: 600;
}

.user-detail-name {
  margin-bottom: 0.5rem;
  margin-right: 4px;
}

.user-detail-right-border {
  border-right: 1.6px solid var(--clr-grey-default);
}

.user-detail-value {
  font-weight: 600;
  color: var(--clr-text-primary);
  white-space: nowrap;
}

.gap-3 {
  gap: 1rem;
}
