.search-input {
  display: block;
  width: 100%;
  border: none;
  padding: 12px;
  border-radius: 5px;
}
.search-input::placeholder {
  color: #a5a5a5;
}

.rdt_TableHeader {
  padding-left: 0px !important;
}
