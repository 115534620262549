.rf-pagination {
    display: flex;
    align-items: center;
    margin: 10px 0;

    font-family: inherit;
    font-size: 12px;
    line-height: 16px;
    color: var(--clr-text-primary);
}

.rf-pagination__list {
    list-style: none;
    display: flex;
    align-items: center;

    margin: 0;
    padding: 0;
}

.rf-pagination__item {
    user-select: none;
}

.rf-pagination__item:not(:last-child) {
    margin-right: 8px;
}

.rf-pagination__btn {
    font-weight: 500;

    background: none;
    padding: 2px 8px;
    color: var(--clr-brand-default);
    border: 1px solid transparent;
    border-radius: 6px;
    transition: border-color .2s cubic-bezier(0.3, 0, 0.5, 1);
}
.rf-pagination__btn:hover {
    border-color: var(--clr-brand-default);
    outline: 0;
    transition-duration: .1s;
}
.rf-pagination__btn.active {
    color: #fff;
    background-color: var(--clr-brand-default);
    border-color: transparent;
    pointer-events: none;
}

.rf-pagination__btn--action {
    font-weight: 500;

    background: none;
    max-width: 32px;
    padding: 2px 8px;
    color: var(--clr-brand-default);
    border: none
}

.rf-pagination__btn--action:disabled {
    color: var(--clr-text-tertiary);
}

.rf-pagination__jump-to {
    display: flex;
    align-items: center;
    font-size: 13px;
    margin-left: 20px;
}

.rf-pagination__jump-to--text {
    margin: 0;
    margin-right: 10px;
}

.rf-pagination__jump-to--input {
    width: 36px;
    height: 20px;
    padding: 2px 6px;
    border: 1px solid var(--clr-text-primary);
    border-radius: 6px;
    appearance: none;

}
.rf-pagination__jump-to--input::-webkit-inner-spin-button, 
.rf-pagination__jump-to--input::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}