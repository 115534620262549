
/* TODO: This is temp, need to fix later */
.rf-search-input {
  border: none;
  background-color: var(--clr-grey-light);
  font-size: 14px;
  line-height: 20px;
  padding-top: 6px;
  padding-bottom: 6px;
  width: 280px;
}

.rf-search-input:focus {
  border: none;
}
