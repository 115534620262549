.rf-filter__wrapper {
    padding: 12px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: var(--clr-text-secondary);
}

.rf-filter__title {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: var(--clr-text-primary);
}

.rf-filter__row-container {
    display: grid;
    column-gap: 10px;
    grid-template-columns: 1fr 2fr 1fr 2fr 20px;
    align-items: center;
    margin-bottom: 16px;
}

.rf-filter__row-item__close {
    grid-column-end: 6;
}


/* HACK to override select style for now */
/* Will migrate to custom select elemnent later, then this override will not be required */
.rf-filter__wrapper .rf-input__container__input {
    padding: 6px 10px;
    font-size: 14px;
    line-height: 20px;
}
.rf-filter__wrapper .rf-input__container__right-section {
    width: 24px;
}
.rf-filter__wrapper .rf-input__container__input.right-section {
    padding-right: 24px;
}